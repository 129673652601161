import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal, Popover, OverlayTrigger } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { getMembership } from '../services/MembershipService.js'

function CdexSenderCodeModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>CDEX Sender Code - {props.selectedOperator.operatorName}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>CDEX Sender Code is needed for CDEX file creation.  Do you wish to add it now?</h6>
                <Form.Group className="mb-3" controlId="cdexSenderCode">
                    <Form.Label>Cdex Sender Code</Form.Label>
                    <Form.Control name="cdexSenderCode" size="sm" aria-label="Cdex Sender Code" value={props.selectedOperator.cdexSenderCode} style={{ width: '100' }} onChange={e => props.setSelectedOperator({ ...props.selectedOperator, cdexSenderCode: e.currentTarget.value.substring(0, 2) })} />
                </Form.Group>
                <Button
                    type='button'
                    size='sm'
                    style={{ marginTop: '20px', width: '100px' }}
                    disabled={false}
                    onClick={() => props.updateCdexSenderCode(props.selectedOperator.operatorId, props.selectedOperator.cdexSenderCode)}
                >
                    Save
                </Button>
                <Button
                    type='button'
                    size='sm'
                    style={{ marginTop: '20px', marginLeft: '20px', width: '100px' }}
                    disabled={false}
                    onClick={props.onHide}
                >
                    Cancel
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function MembershipModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'right' }}>
                            <FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer', padding: '5px', width: '25px', height: '25px' }} onClick={props.onHide} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <div style={{ color: 'red', fontSize: '1.1rem', fontWeight: '700' }} >Excel/CDEX formats free for 2 months!!</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <div style={{ color: 'red', fontSize: '1.1rem', fontWeight: '700' }} >Only $12/month after for all Operator data.</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <div style={{ color: 'red', fontSize: '1.1rem', fontWeight: '700' }} >Cancel anytime.</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Button size="lg"
                                onClick={props.onMemberButtonClick}
                                style={{
                                    width: '350px',
                                    border: '1px solid #C8B568',
                                    borderRadius: '20px',
                                    backgroundColor: '#C8B568',
                                    color: '#fff',
                                    fontSize: '1rem',
                                    fontWeight: '600',
                                    padding: '5px',
                                    marginTop: '16px',
                                    marginBottom: '10px'
                                }} >
                                Yes - Upgrade my Membership Now!
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const CheckFiles = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [owners, setOwners] = useState([]);
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [checkStatements, setCheckStatements] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [statusItems, setStatusItems] = useState(['', 'Released', 'Downloaded']);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 50,
        operatorId: 0,
        ownerId: 0,
        ownerName: '',
        fromMonth: '',
        fromYear: '',
        toMonth: '',
        toYear: '',
        statusId: 0
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [screenHeight, setScreenHeight] = useState(100);

    const [modalShow, setModalShow] = useState(false);
    const [memberModalShow, setMemberModalShow] = useState(false);

    const popoverMembership = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Membership</Popover.Header>
            <Popover.Body>
                <Container>
                    <Row style={{ fontSize: '.7rem', color: 'black', fontWeight: '700', marginTop: '-12px', textAlign: 'center' }}>
                        Unlimited XLS/CDEX  for all your operators data. Upgrade to Blackgold membership!<br />Only $120/year<br />{(accountProfile.displayTrialModal ? '2 month trial period currently in effect.' : '')}
                    </Row>
                    <Row className="justify-content-md-center" style={{marginTop: '10px'}} >
                        <Button size="sm"
                            onClick={() => navigate('/membership')}
                            style={{
                                width: '225px',
                                border: '1px solid #C8B568',
                                borderRadius: '20px',
                                backgroundColor: '#C8B568',
                                color: '#fff',
                                fontSize: '.75rem',
                                fontWeight: '600',
                                padding: '0px',
                                marginTop: '5px'
                            }} >
                            Yes - Upgrade my Membership Now!
                        </Button>
                    </Row>
                </Container>
            </Popover.Body>
        </Popover>
    );

    const popoverNoFee = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Membership</Popover.Header>
            <Popover.Body>
                <Container>
                    <Row style={{ fontSize: '.8rem', fontWeight: '700', marginTop: '-12px' }}>
                        This download is included in your current membership plan.  No fee to download.
                    </Row>
                </Container>
            </Popover.Body>
        </Popover>
    );

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //get inner browser window height
            const height = window.innerHeight;
            setScreenHeight(height);

            let getMembershipFailed = false;
            if ((accountProfile.subscriptionTypeId ?? 0) === 0) {
                await getMembership(currentAccount.id, getAccessTokenSilently, accountProfile, setAccountProfile)
                    .then(response => {
                        if (response.success) {
                            setPageStatus('ready');
                        }
                        else {
                            setPageStatus('error');
                            setPageErrors('Failed to get account membership info.');
                            getMembershipFailed = true;
                        }
                    })
                    .catch(ex => {
                        //TODO: handle so payment button doesn't get displayed if membership not set (or verify it doesn't get displayed)
                        //also, don't go to initializeForm below if we errored here
                        setPageStatus('error');
                        setPageErrors(ex.message);
                    })
                    .finally(response => {
                        //do something
                    });
            }

            if (getMembershipFailed) {
                return;
            } else {
                const displayTrialModal = localStorage.getItem('displayTrialModal');
                if ((displayTrialModal ?? '') === 'true') {
                    //don't show membership modal popup; keeping code though in case we need it again later
                    //setMemberModalShow(true);
                } else {
                    setMemberModalShow(false);
                }
                localStorage.setItem('displayTrialModal', 'false');

                await initializeForm()
                    .then(response => {
                        setOperators(response.operators);
                        setOwners(response.owners);
                        setYears(response.dates.years);
                        setMonths(response.dates.months);

                        setDataCriteria({
                            ...dataCriteria,
                            operatorId: response.operators[0].id,
                            //ownerId: response.owners[0] === null ? 0 : response.owners[0].id,
                            fromMonth: response.dates.fromMonth,
                            fromYear: response.dates.fromYear,
                            toMonth: response.dates.currMonth,
                            toYear: response.dates.currYear
                        });

                        const currentCriteria = {
                            skipCount: 0,
                            takeCount: 50,
                            operatorId: response.operators[0].id,
                            ownerId: 0,
                            ownerName: '',
                            fromMonth: response.dates.fromMonth,
                            fromYear: response.dates.fromYear,
                            toMonth: response.dates.currMonth,
                            toYear: response.dates.currYear,
                            statusId: 0
                        };

                        populateCheckStatementsInitial(currentCriteria)
                            .then(response => {
                                if (response.data.length === 0) {
                                    console.log(response.data);
                                    setDataStatus('nodata')
                                }
                                else {
                                    //console.log('Check Count: ' + response.data[0].rowCount);
                                    setRowCount(response.data[0].rowCount);
                                    setCheckStatements(response.data);
                                    setDataStatus('ready')
                                }

                                setPageStatus('ready');
                            })
                    })
                    .catch(ex => {
                        setPageStatus('error');
                        setPageErrors(ex.message);
                    })
                    .finally(response => {
                        //do something
                    })
            }
        })();
    }, [window.innerHeight]);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOwners() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        const dates = await getDates();

        //get operators for dropdown
        const operators = await getOperators();
        const newOperators = [{id: 0, companyName: ''}, ...operators.data];

        //get owners for dropdown
        const owners = await getOwners();

        return { success: true, operators: newOperators, owners: owners.data, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const currDate = new Date();
        //const day = date.getDate();
        const currMonth = currDate.getMonth() + 1;
        const currYear = currDate.getFullYear();

        //const fromMonth = currMonth === 1 ? 11 : currMonth - 1;   //temp for testing (to list Nov. for 3G LLC)
        const fromMonth = currMonth === 1 ? 12 : currMonth - 2;
        const fromYear = currMonth === 1 ? currYear - 1 : currYear;
        
        const years = [currYear - 3, currYear - 2, currYear - 1, currYear, currYear + 1];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return { months: months, years: years, currMonth: currMonth, currYear: currYear, fromMonth: fromMonth, fromYear: fromYear };
    };

    //get operators (operators this account can receive check statements from - ie. not operators "managed by this account")
    const getOperators = async () => {
        //console.log('Getting Operators...');

        console.log('AccountId: ' + currentAccount.id);

        let apiUrl = 'accounts/' + currentAccount.id + '/checksenders';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //get owners (owners managed by this account)
    const getOwners = async () => {
        //console.log('Getting Owners...');

        console.log('AccountId: ' + currentAccount.id);

        const apiUrl = 'accounts/' + currentAccount.id + '/managedreceivers';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form submit
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setCurrentPage(1);

        await populateCheckStatements(0)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }                
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);

        setDataStatus('loading');

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        await populateCheckStatements(skipCount)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    console.log('Check Count: ' + response.data[0].checkCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //populate check statements
    const populateCheckStatements = async (skipCount) => {
        //console.log('Getting CheckStatements...');

        const apiUrl = 'accounts/' + currentAccount.id + '/checkstatements?operatorId=' + dataCriteria.operatorId + '&skipCount=' + skipCount + '&takeCount=' + dataCriteria.takeCount + '&ownerName=' + dataCriteria.ownerName + '&fromMonth=' + dataCriteria.fromMonth + '&fromYear=' + dataCriteria.fromYear + '&toMonth=' + dataCriteria.toMonth + '&toYear=' + dataCriteria.toYear + '&statusId=' + dataCriteria.statusId;
        //console.log(apiUrl);
        
        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //populate check statements
    const populateCheckStatementsInitial = async (currentCriteria) => {
        //console.log('Getting CheckStatements...');

        const apiUrl = 'accounts/' + currentAccount.id + '/checkstatements?operatorId=' + currentCriteria.operatorId + '&skipCount=' + currentCriteria.skipCount + '&takeCount=' + currentCriteria.takeCount + '&ownerName=' + currentCriteria.ownerName + '&fromMonth=' + currentCriteria.fromMonth + '&fromYear=' + currentCriteria.fromYear + '&toMonth=' + currentCriteria.toMonth + '&toYear=' + currentCriteria.toYear + '&statusId=' + currentCriteria.statusId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    const downloadCdex = async (exportType, checkId, index) => {

        const checkStatement = { ...checkStatements[index] };
        const senderId = checkStatement.operatorId;
        const cdexSenderCode = checkStatement.cdexSenderCode;
        console.log('CheckId: ' + checkId + ', SenderId: ' + senderId + ', Cdex Sender Code: ' + cdexSenderCode);
        if ((cdexSenderCode ?? '') == '') {
            setSelectedOperator(checkStatement);
            setModalShow(true);
        }
        else {
            //alert('downloading...');
            await downloadPdf(exportType, checkId, index);
        }       
    }

    //update cdexsendercode
    const updateCdexSenderCode = async (operatorId, cdexSenderCode) => {
        console.log('Saving cdexsendercode...');
        //setDataStatus('loading');

        console.log('OperatorId: ' + operatorId + ', CdexSenderCode: ' + cdexSenderCode);

        var selectedCompany = { id: operatorId, cdexSenderCode: cdexSenderCode };

        await saveCdexSenderCode(selectedCompany)
            .then(response => {
                if (response.success) {
                    setCheckStatements(checkStatements.map((checkStatement, index) => {
                        if (checkStatement.operatorId == selectedCompany.id) {
                            return { ...checkStatement, cdexSenderCode: cdexSenderCode };
                        }
                        else {
                            return checkStatement;
                        }
                    }));
                    console.log('ready');
                    //setDataStatus('ready');
                    setModalShow(false);
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save cdexsendercode
    const saveCdexSenderCode = async (selectedCompany) => {
        console.log('Saving cdexsendercode...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatesendingoperator/' + selectedCompany.id;
        const jsonBody = JSON.stringify(selectedCompany);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    const downloadPdf = async (exportType, checkId, index) => {
        try {
            setDataStatus('loading');

            var selectedStatement = checkStatements.filter(function (statement) {
                return statement.checkId === parseInt(checkId);
            })[0];

            let fileName = selectedStatement.fileNamePrefix + '.';
            if (exportType === 'Cdex') {
                fileName = selectedStatement.cdexFileName;
            }            

            //let apiUrl = baseUrl + 'a_export/';
            let apiUrl = 'a_export/';
            switch (exportType) {
                case 'Pdf':
                    apiUrl = 'a_report/pdfreport';
                    fileName = fileName + 'pdf';
                    break;
                case 'Excel':
                    apiUrl = apiUrl + 'excel';
                    fileName = fileName + 'xlsx';
                    break;
                case 'Cdex':
                    apiUrl = apiUrl + 'cdex';
                    //fileName = fileName + 'dat';                    
                    break;
                default:
                    break;
            }

            apiUrl = apiUrl + '?checkid=' + checkId + '&flaggenerated=true';
            //console.log(apiUrl);

            await getDocument(checkId, apiUrl).then(response => {
                response.data.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();

                    //https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                    let checks = [...checkStatements];
                    let check = { ...checkStatements[index] };
                    check.ownerStatus = true;
                    checks[index] = check;
                    setCheckStatements(checks);

                    setDataStatus('ready');
                })
            });
        } catch (ex) {
            console.log(ex.message);
            setDataStatus('error');
            setDataErrors(ex.message);
        }        
    };

    //getDocument
    const getDocument = async (checkId, apiUrl) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{ height: (screenHeight - 410).toString() + 'px', overflow: "auto" }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'lightgray' }}>
                    <Container fluid style={{ height: '40px' }} >
                    {(() => {
                        if (dataStatus == 'ready') {
                            return <Row>
                                        <Col className="d-flex justify-content-end">
                                            <PaginationComponent
                                                itemsCount={rowCount}
                                                itemsPerPage={dataCriteria.takeCount}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageClickHandler={handlePageClick}
                                                alwaysShown={true}
                                            />
                                        </Col>
                                    </Row>                                    
                        }
                    })()}       
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formOperator">
                                <Form.Label>Operated Company</Form.Label>
                                <Form.Select name="operatorId" size="sm" aria-label="Operator" onChange={handleChange} >
                                    {operators.map((operator, i) => (
                                        <option key={i} value={operator.id}>{operator.companyName}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {/*<Col xs="auto">*/}
                        {/*    <Form.Group as={Col} className="mb-3" controlId="formOwner">*/}
                        {/*        <Form.Label>Non-Operated Company</Form.Label>*/}
                        {/*        <Form.Select name="ownerId" size="sm" aria-label="Owner" onChange={handleChange} >*/}
                        {/*            {owners.map((owner, i) => (*/}
                        {/*                <option key={i} value={owner.id}>{owner.companyName}</option>*/}
                        {/*            ))}*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerName">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control name="ownerName" size="sm" aria-label="Company Code" value={dataCriteria.ownerName} style={{ width: '100' }} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="fromMonth">
                                <Form.Label>From</Form.Label>
                                <Form.Select name="fromMonth" size="sm" value={dataCriteria.fromMonth} aria-label="FromMonth" onChange={handleChange} >
                                    {months.map((month, i) => (
                                        <option key={i} value={i + 1}>{month}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="fromYear">
                                <Form.Label>&nbsp;</Form.Label>
                                <Form.Select name="fromYear" size="sm" value={dataCriteria.fromYear} aria-label="FromYear" onChange={handleChange} >
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="toMonth">
                                <Form.Label>To</Form.Label>
                                <Form.Select name="toMonth" size="sm" value={dataCriteria.toMonth} aria-label="ToMonth" onChange={handleChange} >
                                    {months.map((month, i) => (
                                        <option key={i} value={i + 1}>{month}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="toYear">
                                <Form.Label>&nbsp;</Form.Label>
                                <Form.Select name="toYear" size="sm" value={dataCriteria.toYear} aria-label="ToYear" onChange={handleChange} >
                                    {years.map((year, i) => (
                                        <option key={i} value={year}>{year}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Select name="statusId" size="sm" aria-label="Status" onChange={handleChange} >
                                    {statusItems.map((status, i) => (
                                        <option key={i} value={i}>{status}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='submit'
                                    size="sm"
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    //render datafiles
    const showData = () => {
        return (
            <Container fluid>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>CDEX Sender Code</th>
                                <th>Operator Name</th>
                                <th>Op Owner #</th>
                                <th>Owner Name</th>
                                <th>Check Number</th>
                                <th>Check Date</th>
                                <th>Check Amount</th>
                                <th>Status</th>
                                <th colSpan={3} style={{ textAlign: "center" }}>
                                    {/*{(() => {*/}
                                    {/*    if (accountProfile.hasImpersonate) {*/}
                                    {/*        return <p style={{ marginBottom: '0px', color: 'red', fontWeight: '800', fontSize: '1.1rem' }}>All formats are FREE!!</p>*/}
                                    {/*    }*/}
                                    {/*})()} */}
                                    {/*<p style={{ marginBottom: '0px', color: 'red', fontWeight: '800', fontSize: '1.1rem' }}>All formats are FREE!!</p>*/}
                                    <p style={{ marginBottom: '0px' }}>Check Statements</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {checkStatements.map((checkStatement, index) => (
                                <tr key={checkStatement.checkId}>
                                    <td>{checkStatement.cdexSenderCode}</td>
                                    <td>{checkStatement.operatorName}</td>
                                    <td>{checkStatement.ownerCompanyCode}</td>
                                    <td>{checkStatement.ownerName}</td>
                                    <td>{checkStatement.checkNumber}</td>
                                    <td>{checkStatement.checkDateString}</td>
                                    <td>{checkStatement.checkAmountString}</td>
                                    <td>{(checkStatement.ownerStatus === true ? "Downloaded" : "Released")}</td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "100px", marginLeft: "20px" }} value={checkStatement.checkId}
                                            onClick={(e) => downloadPdf('Pdf', e.currentTarget.value, index)}>
                                            Create PDF
                                        </Button>
                                    </td>
                                    <td>
                                        {(() => {
                                            if (accountProfile.displayTrialModal || (accountProfile.subscriptionTypeId === 2 && accountProfile.subscriptionStatusId === 2)) {
                                                return <OverlayTrigger placement="top" overlay={accountProfile.displayTrialModal ? popoverMembership : popoverNoFee} delay={{ show: 500, hide: 1000 }}>
                                                    <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "100px" }} value={checkStatement.checkId}
                                                        disabled={!checkStatement.dataFileReleased}
                                                        onClick={(e) => downloadPdf('Excel', e.currentTarget.value, index)}>
                                                        Create Excel
                                                    </Button>
                                                </OverlayTrigger>
                                            } else {
                                                return <OverlayTrigger placement="top" overlay={popoverMembership} delay={{ show: 500, hide: 1000 }}>
                                                    <Button id={checkStatement.checkId} size="sm" style={{ width: "100px", backgroundColor: '#5c9cf9', border: '1px solid #5c9cf9', cursor: 'auto' }} value={checkStatement.checkId}
                                                        onClick={null}>
                                                        Create Excel
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                        })()}
                                    </td>
                                    <td>
                                        {(() => {
                                            if (accountProfile.displayTrialModal || (accountProfile.subscriptionTypeId === 2 && accountProfile.subscriptionStatusId === 2)) {
                                                return <OverlayTrigger placement="top" overlay={accountProfile.displayTrialModal ? popoverMembership : popoverNoFee} delay={{ show: 500, hide: 1000 }}>
                                                    <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "100px" }} value={checkStatement.checkId}
                                                        disabled={!checkStatement.dataFileReleased}
                                                        onClick={(e) => downloadCdex('Cdex', e.currentTarget.value, index)}>
                                                        Create CDEX
                                                    </Button>
                                                </OverlayTrigger>
                                            } else {
                                                return <OverlayTrigger placement="top" overlay={popoverMembership} delay={{ hide: 4000 }}>
                                                    <Button id={checkStatement.checkId} size="sm" style={{ width: "100px", backgroundColor: '#5c9cf9', border: '1px solid #5c9cf9', cursor: 'auto' }} value={checkStatement.checkId}
                                                        onClick={null}>
                                                        Create CDEX
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                        })()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Container>
        );
    }

    //render component
    return (
        <div>
            {(() => {
                if (false) {
                    return <Container>
                        <Row style={{marginTop: '-12px', marginBottom: '-12px'}} >
                            <Col xs={8} style={{ marginLeft: '-12px' }} >
                                <h1>Check Statements</h1>
                            </Col>
                            <Col xs={4} >
                                <Container>
                                    <Card className="mb-4 shadow" style={{margin: '0px', padding: '0px'}} >
                                        <Card.Body style={{ margin: '0px', padding: '8px' }}>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Excel/CDEX formats free for 2 months!!</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Only $12/month after for all Operator data.</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Cancel anytime.</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <Button size="sm"
                                                onClick={() => navigate('/membership')}
                                                style={{
                                                width: '225px',
                                                border: '1px solid #C8B568',
                                                borderRadius: '20px',
                                                backgroundColor: '#C8B568',
                                                color: '#fff',
                                                fontSize: '.75rem',
                                                fontWeight: '600',
                                                padding: '0px',
                                                marginTop: '5px'
                                            }} >
                                                Yes - Upgrade my Membership Now!
                                            </Button>
                                        </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                } else {
                    return <>
                        <h1>Check Statements</h1>
                        <div><br /></div>
                    </>
                }
            })()}
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <CdexSenderCodeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onYes={() => navigate('/sendingoperators')}
                selectedOperator={selectedOperator}
                setSelectedOperator={setSelectedOperator}
                updateCdexSenderCode={updateCdexSenderCode}
            />

            <MembershipModal
                show={memberModalShow}
                onHide={() => setMemberModalShow(false)}
                onMemberButtonClick={() => navigate('/membership')}
            />
        </div>
    );
}

export default CheckFiles;
