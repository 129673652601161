import { useNavigate } from "react-router-dom";
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import TOTLogo from './/./../../../assets/brand/ColorLogo_ImageOnly.png';

const logoStyle = {
    height: '40px',
    width: '60%',
    color: 'transparent',
    cursor: 'pointer',
    marginTop: '8px',
    marginBottom: '8px'
};

const membershipBenefitsUpgrade = (
    <Popover id="popover-basic">
        <Popover.Header as="h3" style={{ backgroundColor: '#C8B568', color: '#000' }} ><p style={{ fontSize: '.85rem', fontWeight: '700', textAlign: 'center', padding: '0px', margin: '0px' }} >Current Membership Benefits</p></Popover.Header>
        <Popover.Body style={{ padding: '10px' }} >
            <ul style={{ fontSize: '.75rem', padding: '0px', margin: '0px', paddingLeft: '10px' }} ><li>Unlimited PDF Operator data, 1099s</li><li>2 month trial of unlimited XLSX/CDEX data</li></ul>
        </Popover.Body>
    </Popover>
);

const membershipBenefitsBlackGold = (
    <Popover id="popover-basic">
        <Popover.Header as="h3" style={{ backgroundColor: '#C8B568', color: '#000' }} ><p style={{ fontSize: '.85rem', fontWeight: '700', textAlign: 'center', padding: '0px', margin: '0px' }} >Current Membership Benefits</p></Popover.Header>
        <Popover.Body style={{ padding: '10px' }} >
            <ul style={{ fontSize: '.75rem', padding: '0px', margin: '0px', paddingLeft: '10px' }} ><li>Unlimited PDF XLSX/CDEX data, 1099s</li></ul>
        </Popover.Body>
    </Popover>
);

export default function HeaderMembership(props) {
    //stateful properties (hooks)
    const navigate = useNavigate();

    const { accountProfile } = props

    return (
        <Container style={{ border: 'none', background: '#000', borderRadius: '5px', paddingLeft: '20px', marginTop: '-8px', marginBottom: '-8px' }} >
            <Row>
                <Col xs={4} style={{ padding: '0px' }}>
                    {(() => {
                        if (accountProfile.isMembershipStatusLoaded) {
                            return <OverlayTrigger placement="right" overlay={(accountProfile.subscriptionName === "BlackGold" ? membershipBenefitsBlackGold : membershipBenefitsUpgrade)}>
                                <div style={{ marginTop: '5px', color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: '700', fontSize: '.8rem', cursor: 'pointer' }} >
                                    <span style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Membership status:&nbsp;&nbsp;</span><span style={{ color: (accountProfile.subscriptionName === "BlackGold" ? "#C8B568" : '#fff') }} >{(accountProfile.subscriptionName === "BlackGold" ? "BlackGold" : (accountProfile.subscriptionName === "Trial" ? "BlackGold Trial" : "Wildcatters"))}</span>
                                    <p style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><span style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', visibility: 'hidden' }}>Member since:&nbsp;&nbsp;8/05/2024</span></p>
                                </div>
                                </OverlayTrigger>
                            
                        }
                    })()}
                </Col>
                <Col xs={4} style={{ marginBottom: '0px', fontWeight: '700', fontSize: '.75rem', textAlign: 'center', color: '#fff', paddingTop: '1px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <img
                        object-fit="fill"
                        src={TOTLogo}
                        alt="totdata.com"
                        style={logoStyle}
                        onClick={() => navigate('/')}
                    />
                </Col>                
                <Col xs={4} style={{ marginTop: '4px' }} >
                    {(() => {
                        if (accountProfile.isMembershipStatusLoaded && (accountProfile.subscriptionName !== "BlackGold")) {
                            return <div style={{ color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: '700', fontSize: '.8rem', cursor: 'pointer' }} onClick={() => navigate('/membership')} >
                                <span style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Click here to upgrade to </span><span style={{ color: '#C8B568'}} >BlackGold status</span>
                                <p style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><span style={{ margin: '0px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Benefits:&nbsp;&nbsp;</span><span style={{ color: '#C8B568' }} >Unlimited XLSX/CDEX<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only $120/Year!</span></p>
                            </div>
                        }
                    })()}
                </Col>
            </Row>
        </Container>
  )
}
