import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card, Modal, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import ReCAPTCHA from "react-google-recaptcha"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const popoverOperatorName = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Operator Name</Popover.Header>
        <Popover.Body>
            This represents the company that is sending your statements (the company that sends the checks).
        </Popover.Body>
    </Popover>
);

function RegisterModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Register</h4>
                    <h6>Enter the criteria below to register and link to your documents.</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ height: '650px' }} >
                    <Form noValidate>
                        <Form.Group as={Row} className="mb-3" controlId="region" style={{ marginTop: '30px' }}>
                            <Form.Label column sm={3}>Operator's Region</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="region" size="sm" value={props.dataCriteria.region} aria-label="Operator's Region" onChange={props.handleChange} >
                                    {props.regions.map((region, i) => (
                                        <option key={i} value={region.id}>{region.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="docType">
                            <Form.Label column sm={3}>Document Type</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="docType" size="sm" value={props.dataCriteria.docType} aria-label="Document Type" onChange={props.handleChange} >
                                    {props.documentTypes.map((docType, i) => (
                                        <option key={i} value={docType.id}>{docType.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="operatorName">
                            <Form.Label column sm={3}>Operator Name
                                <OverlayTrigger placement="right" overlay={popoverOperatorName}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer', marginLeft: '5px', width: '17px', height: '17px' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control name="operatorName" size="sm" aria-label="Operator Name" value={props.dataCriteria.operatorName} onChange={props.handleChange} isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.operatorName : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter operator's name (at least first 3 letters).
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={4}>
                                "The company that sends the checks"
                                <Button
                                    hidden={props.numTries < 2}
                                    size='sm'
                                    style={{ width: '150px', marginTop: '10px' }}
                                    disabled={props.dataStatus != 'ready' || props.numTries < 2}
                                    onClick={props.helpButtonClick}
                                >
                                    Find Operator
                                </Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="ownerNumber">
                            <Form.Label column sm={3}>Owner Number</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={props.dataCriteria.ownerNumber} onChange={props.handleChange} isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.ownerNumber : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter owner number.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="checkMonth">
                            <Form.Label column sm={3}>Check/ACH Month</Form.Label>
                            <Col sm={2}>
                                <Form.Select name="checkYear" size="sm" value={props.dataCriteria.checkYear} aria-label="Check Year" onChange={props.handleChange} >
                                    {props.years.map((year, i) => (
                                        <option key={i} value={year.year.num}>{year.year.num}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={2}>
                                <Form.Select name="checkMonth" size="sm" value={props.dataCriteria.checkMonth} aria-label="Check Month" onChange={props.handleChange} >
                                    {props.months.map((month, i) => (
                                        <option key={i} value={month.index}>{month.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="checkAmount">
                            <Form.Label column sm={3}>Check Amount</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="checkAmount" size="sm" aria-label="Check Amount" maxLength={15} value={props.dataCriteria.checkAmount} onChange={props.handleChange} isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.checkAmount : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter check amount.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="email">
                            <Form.Label column sm={3}>Email</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="email" size="sm" aria-label="Email" value={props.dataCriteria.email} onChange={props.handleChange} placeholder="john.doe@gmail.com" isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.email : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="confirmEmail">
                            <Form.Label column sm={3}>Confirm Email</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="confirmEmail" size="sm" aria-label="Confirm Email" value={props.dataCriteria.confirmEmail} onChange={props.handleChange} placeholder="john.doe@gmail.com" isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.confirmEmail : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Emails must match.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col sm={3}>
                            </Col>
                            <Col sm={6}>
                                <Card className="mb-4">
                                    <Card.Header>
                                        <Form.Check
                                            type="checkbox"
                                            name="confirmTOC"
                                            label="Confirm Terms and Conditions"
                                            checked={props.dataCriteria.confirmTOC}
                                            onChange={props.handleCheckboxClick}
                                            feedback="You must confirm before submitting."
                                            feedbackType="invalid"
                                            isInvalid={(props.isRegisterClicked == true ? !props.isDataValid.confirmTOC : false)}

                                        />
                                    </Card.Header>
                                    <Card.Body>
                                        <div>I authorize TOTData.com and its designated agents and representatives to use my information to protect against or prevent actual or potential fraud, unauthorized transactions or access, or other liability.</div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                            </Col>
                            <Col sm={5}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={props.captchaRef}
                                    onChange={props.handleReCaptchaChange}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }} >
                            <Col className="d-flex justify-content-center">
                                <div style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '20px' }} ><i>Note: This is a one-time process to register and securely link your account to your documents.</i></div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {(() => {
                        if (props.invalidText != "") {
                            return <Row>
                                    <Col xs={3} />
                                    <Col xs={9} style={{ display: 'flex', justifyContent: 'left' }} >
                                    <p style={{ color: 'red' }}><b>{props.invalidText}</b></p>
                                    </Col>
                                </Row>
                        }
                    })()}
                    <Row>
                        <Col xs={3} >
                            <Button style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col xs={9} style={{ display: 'flex', justifyContent: 'left' }} >
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Button
                                    size='md'
                                    style={{ width: '400px' }}
                                    disabled={props.dataStatus != 'ready' || !props.isDirty}
                                    onClick={() => props.handleFormButtons('Save')}
                                >
                                    Register
                                </Button>
                            </Form.Group>                            
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function HelpModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Find Operator</h4>                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{ borderBottom: '2px solid lightgray', marginBottom: '30px' }}>
                        <Col>
                            <p>If the Operator name you entered was not found, please enter the Operator's name (and any alternate names/alias's) below, along with your name and email address.  We will investigate and get back to you as soon as possible.</p>
                        </Col>
                    </Row>
                    <Form noValidate>
                        <Form.Group as={Row} className="mb-3" controlId="operatorNames">
                            <Form.Label column sm={4}>Operator Name(s)</Form.Label>
                            <Col sm={8}>
                                <Form.Control name="operatorNames" as="textarea" rows={3} style={{ resize: 'none' }} maxLength={100} size="sm" aria-label="Operator Name(s)" value={props.helpCriteria.operatorNames} onChange={props.handleHelpDataChange} isInvalid={!props.isHelpValid.operatorNames} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter operator's name(s).
                                </Form.Control.Feedback>
                                <p style={{fontSize: '12px', marginTop: '5px', marginBottom: '0px'}} >Limit (100 characters)</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="requestorName">
                            <Form.Label column sm={4}>Your Name</Form.Label>
                            <Col sm={8}>
                                <Form.Control name="requestorName" size="sm" aria-label="Your Name" value={props.helpCriteria.requestorName} onChange={props.handleHelpDataChange} placeholder="Enter your name" isInvalid={!props.isHelpValid.requestorName} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your name.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="requestorEmail">
                            <Form.Label column sm={4}>Your Email</Form.Label>
                            <Col sm={8}>
                                <Form.Control name="requestorEmail" size="sm" aria-label="Email" value={props.helpCriteria.requestorEmail} onChange={props.handleHelpDataChange} placeholder="john.doe@gmail.com" isInvalid={!props.isHelpValid.requestorEmail} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col xs={3} >
                            <Button style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col xs={9} style={{ display: 'flex', justifyContent: 'right' }} >
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Button
                                    size='md'
                                    style={{ width: '100px' }}
                                    disabled={props.dataStatus != 'ready' || props.invalidHelpText != ""}
                                    onClick={() => props.handleFormButtons('Help')}
                                >
                                    Submit
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function RegisterResultModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: (props.registrationStatus == 'success' ? '#d1e7dd' : '#f8d7da') }} >
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{(props.registrationStatus == 'success' ? 'Registration Success!' : 'Registration Failed')}</h4>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: (props.registrationStatus == 'success' ? '#d1e7dd' : '#f8d7da'), marginTop: '0px', paddingTop: '0px' }} >
                <Alert variant={props.alertVariant} show={props.alertShow} onClose={props.setAlertShow} dismissible={false} style={{border: 'none'}} >
                    <h5>{props.alertMessage}</h5>
                </Alert>
                <Container>
                    <Row style={{ marginTop: '20px' }} >
                        <Col style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button
                                onClick={props.onHide}
                            >
                                {(props.registrationStatus == 'success' ? 'Go to Home Page' : 'Try Again')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function HelpResultModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: (props.helpResultStatus == 'success' ? '#d1e7dd' : '#f8d7da') }} >
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{(props.helpResultStatus == 'success' ? 'Success!' : 'Failed')}</h4>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: (props.helpResultStatus == 'success' ? '#d1e7dd' : '#f8d7da'), marginTop: '0px', paddingTop: '0px' }}>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginLeft: '30px', marginRight: '30px' }} >
                            <p><b>{props.helpResultText}</b></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }} >
                            <Button
                                onClick={props.onHide}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const Register = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    
    //stateful properties (hooks)
    const location = useLocation();

    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [registerModalShow, setRegisterModalShow] = useState(true);
    const [helpModalShow, setHelpModalShow] = useState(false);
    const [helpResultModalShow, setHelpResultModalShow] = useState(false);
    const [helpResultStatus, setHelpResultStatus] = useState('fail');
    const [helpResultText, setHelpResultText] = useState("");
    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('ready');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [regions, setRegions] = useState([{ id: 'US', name: 'United States' }, { id: 'CA', name: 'Canada' }, { id: 'OT', name: 'Other' }]);
    const [documentTypes, setDocumentTypes] = useState([{ id: 'REV', name: 'Revenue Statements' }, { id: 'JIB', name: 'JIB' }]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [currentYear, setCurrentYear] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [isDirty, setIsDirty] = useState(false);
    const [isHelpDirty, setIsHelpDirty] = useState(false);
    const captchaRef = useRef(null);
    const [sureModalShow, setSureModalShow] = React.useState(false);
    const [isRegisterClicked, setIsRegisterClicked] = useState(false);
    const [isHelpClicked, setIsHelpClicked] = useState(false);
    const [numTries, setNumTries] = useState(0);
    const [invalidText, setInvalidText] = useState("");
    const [invalidHelpText, setInvalidHelpText] = useState("");
    const [registrationStatus, setRegistrationStatus] = useState('fail');
    const [isDataValid, setIsDataValid] = useState({
        region: true,
        docType: true,
        operatorName: true,
        ownerNumber: true,
        checkMonth: true,
        checkYear: true,
        checkNumber: true,
        checkAmount: true,
        confirmTOC: true,
        email: true,
        confirmEmail: true,
        reCaptchaToken: false
    });
    const [dataCriteria, setDataCriteria] = useState({
        region: 'US',
        docType: 'REV',
        operatorName: '',
        ownerNumber: '',
        checkMonth: 0,
        checkYear: 0,
        checkNumber: '',
        checkAmount: '',
        confirmTOC: false,
        email: '',
        confirmEmail: '',
        reCaptchaToken: ''
    });

    const [alertShow, setAlertShow] = React.useState(true);
    const [alertVariant, setAlertVariant] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('Registration successful!');

    const [helpCriteria, setHelpCriteria] = useState({
        operatorNames: '',
        requestorName: '',
        requestorEmail: ''
    });
    const [isHelpValid, setIsHelpValid] = useState({
        operatorNames: true,
        requestorName: true,
        requestorEmail: true
    });

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');
            
            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            var numMonths = location.state;

            await initializeForm(numMonths)
                .then(response => {

                    //set years
                    setYears(response.dates.years);

                    //set months
                    response.dates.years.map((year, index) => {
                        if (year.year.num == response.dates.currYear) {
                            setMonths(year.months);
                        }
                    });

                    //set current year/month
                    setCurrentMonth(response.dates.currMonth);
                    setCurrentYear(response.dates.currYear);

                    //initialize data criteria year/month
                    setDataCriteria({
                        ...dataCriteria,
                        checkMonth: response.dates.currMonth,
                        checkYear: response.dates.currYear
                    });

                    setPageStatus('ready');
                    //setRegisterModalShow(true);
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //initialize form
    const initializeForm = async (numMonths) => {
        //TODO: might just call populateOwners() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        const dates = await getDates(numMonths);

        return { success: true, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = (numMonths) => {

        const date = new Date();
        const currMonthNum = date.getMonth() + 1;
        //const currMonthNum = 1;
        const currYearNum = date.getFullYear();
        //const currYearNum = 2023;
        //const showMonths = 3;
        const showMonths = ((numMonths > 12) || (numMonths < 1) ? 3 : numMonths);

        const allMonths = [{ index: 1, name: 'Jan' }, { index: 2, name: 'Feb' }, { index: 3, name: 'Mar' }, { index: 4, name: 'Apr' },
            { index: 5, name: 'May' }, { index: 6, name: 'Jun' },{ index: 7, name: 'Jul' }, { index: 8, name: 'Aug' },
            { index: 9, name: 'Sep' }, { index: 10, name: 'Oct' }, { index: 11, name: 'Nov' }, { index: 12, name: 'Dec' }];

        //previous year
        const years = [];
        if (currMonthNum < showMonths) {
            const prevYearMonths = allMonths.slice(12 - (showMonths - currMonthNum));
            const prevYear = { year: { index: 0, num: currYearNum - 1 }, months: prevYearMonths };
            years.push(prevYear);
        }

        //current year
        const currYearMonths = [];
        allMonths.map((month, i) => {
            if (i < currMonthNum && i >= (currMonthNum - showMonths))
                currYearMonths.push(month);
        });
        const currYear = { year: { index: 1, num: currYearNum }, months: currYearMonths };
        years.push(currYear);

        //years.map((year, i) => {
        //    console.log('Year: ' + year.year.num);

        //    year.months.map((month, i) => {
        //        console.log('Month: ' + month.index + ' : ' + month.name);
        //    }); 
        //}); 

        return { years: years, currMonth: currMonthNum, currYear: currYearNum };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setInvalidText("");

        const newDataCriteria = {
            ...dataCriteria,
            [fieldName]: value
        };      

        //handling specific fields
        switch (fieldName) {
            case 'operatorName':
                //default operator help criteria operator name(s) to match registraton criteria
                //setHelpCriteria({
                //    ...helpCriteria,
                //    operatorNames: value
                //});
                break;
            case 'email':
                //default operator help criteria email to match registraton criteria
                //setHelpCriteria({
                //    ...helpCriteria,
                //    requestorEmail: value
                //});
                break;
            case 'checkYear':
                //if setting year, set the selected year index so we can update the month dropdown
                years.map((year, index) => {
                    if (year.year.num == value) {
                        setMonths(year.months);
                        const monthNum = (year.year.num == currentYear ? currentMonth : year.months[0].index);
                        newDataCriteria.checkMonth = monthNum;
                    }
                });
                break;
            case 'checkAmount':
                let positionIncrement = 0;

                //strip dollar sign and commas
                let newValue = value.replace("$", "");
                newValue = newValue.replaceAll(",", "");

                //add zero in front of lone decimal
                if (newValue == ".") {
                    newValue = "0.";
                    positionIncrement = 1;
                }

                //verify it matches a general number pattern
                var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
                const matchFound = newValue.search(validNumber);
                //if (matchFound == -1) {
                //    return;
                //}
                if (matchFound == -1) {
                    //set to old value so we can figure out cursor position below
                    newValue = dataCriteria.checkAmount;

                    //decrement to nullify move back one position since an invalid character was input
                    positionIncrement = -1;
                } else {
                    //convert to numeric value and convert to locale string (ie. add thousand separator); also format string with dollar sign and suffix
                    if (newValue != "") {
                        const numericValue = parseFloat(newValue);

                        //note: parseFloat removes "." and ".0", so add back if in original string
                        const newValueSuffix = (value.endsWith(".") ? "." : (value.endsWith(".0") ? ".0" : ""));
                        newValue = "$" + numericValue.toLocaleString("en-US") + newValueSuffix;
                    }
                }

                //do some gymnastics to figure out cursor position (ie. adjust if comma was added or removed)
                const oldValueComma = dataCriteria.checkAmount.replaceAll(",", "#comma");
                const oldCommaCount = (oldValueComma.match(/#comma/g) || []).length;
                const newValueComma = newValue.replaceAll(",", "#comma");
                const newCommaCount = (newValueComma.match(/#comma/g) || []).length;
                positionIncrement = positionIncrement + (newCommaCount - oldCommaCount);

                if (dataCriteria.checkAmount == "") {
                    positionIncrement = positionIncrement + 1;
                }

                //set the cursor position within the input
                const inputBox = event.target;
                const startPos = inputBox.selectionStart + positionIncrement;
                const endPos = inputBox.selectionEnd + positionIncrement;

                setTimeout(() => {
                    inputBox.setSelectionRange(startPos, endPos);
                }, 10);

                //set the new value in state
                newDataCriteria.checkAmount = newValue;
                break;

            default:
                break;
        }

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //handle form control changes
    const handleHelpDataChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        const newHelpCriteria = {
            ...helpCriteria,
            [fieldName]: value
        };

        //set criteria and isDirty status
        setHelpCriteria(newHelpCriteria);
        checkIsHelpDirty(newHelpCriteria);
        checkIsHelpValid(newHelpCriteria);
    }

    //handle modal display pdf hide button
    const handleHideRegisterModal = () => {
        //navigate("https://totdata.com");
        //window.location.href = "https://totdata.com";
        window.location.replace("https://totdata.com");
        //return null;
        //setRegisterModalShow(false);
    }

    //handle checkbox clicks
    const handleCheckboxClick = (event) => {
        const fieldName = event.target.name;
        const value = event.target.checked;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setInvalidText("");

        const newDataCriteria = {
            ...dataCriteria,
            [fieldName]: value
        };

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //handle recaptcha change
    const handleReCaptchaChange = () => {
        //event.preventDefault();

        setInvalidText("");

        const token = captchaRef.current.getValue();
        //console.log('Setting ReCaptcha Token: ' + token)
        
        const newDataCriteria = {
            ...dataCriteria,
            reCaptchaToken: token
        };

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }
    //handle show help modal
    const handleShowHelpModal = async () => {      

        //default operator help criteria operator name(s) to match registraton criteria
        const newHelpCriteria = {
            ...helpCriteria,
            operatorNames: dataCriteria.operatorName,
            requestorEmail: dataCriteria.email
        };
        setHelpCriteria(newHelpCriteria);

        await checkIsHelpDirty(newHelpCriteria);
        await checkIsHelpValid(newHelpCriteria);
        setIsHelpClicked(false);

        setHelpModalShow(true);
    }


    //get user account
    const checkIsDataDirty = async (newDataCriteria) => {

        const isDirty = newDataCriteria.region.length > 0
            && newDataCriteria.docType.length > 0
            && newDataCriteria.operatorName.length > 2
            && newDataCriteria.ownerNumber.length > 0
            && newDataCriteria.checkMonth > 0
            && newDataCriteria.checkYear > 0
            && newDataCriteria.checkAmount.length > 1
            && newDataCriteria.confirmTOC == true
            && newDataCriteria.email.length > 0
            && newDataCriteria.confirmEmail.length > 0
            && newDataCriteria.reCaptchaToken.length > 0;
        
        setIsDirty(isDirty);
    }

    //get user account
    const checkIsHelpDirty = async (newHelpCriteria) => {

        const isDirty = newHelpCriteria.operatorNames.length > 0
            && newHelpCriteria.requestorName.length > 0
            && newHelpCriteria.requestorEmail.length > 0;

        setIsHelpDirty(isDirty);
    }

    //get user account
    const checkIsDataValid = async (newDataCriteria) => {
        const isOperatorNameValid = newDataCriteria.operatorName.length >= 3;
        const isOwnerNumberValid = newDataCriteria.ownerNumber.length >= 1;

        //**********Begin - Check Amount**********//
        let newValue = newDataCriteria.checkAmount.replace("$", "");
        newValue = newValue.replaceAll(",", "");

        var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
        const matchFound = newValue.search(validNumber);
        let isCheckAmountValid = true;
        if (newValue == "" || matchFound == -1) {
            isCheckAmountValid = false;
        }
        //***********End - Check Amount***********//

        //***************Begin - Email************//
        var validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        const emailMatchFound = newDataCriteria.email.search(validEmail);
        const isEmailValid = emailMatchFound > -1;
        //***************End - Email**************//

        const isConfirmEmailValid = newDataCriteria.email == newDataCriteria.confirmEmail;
        const isConfirmTOCValid = newDataCriteria.confirmTOC;
        const isReCaptchaValid = newDataCriteria.reCaptchaToken.length > 0;

        setIsDataValid({
            ...isDataValid,
            operatorName: isOperatorNameValid,
            ownerNumber: isOwnerNumberValid,
            checkAmount: isCheckAmountValid,
            email: isEmailValid,
            confirmEmail: isConfirmEmailValid,
            confirmTOC: isConfirmTOCValid,
            reCaptchaToken: isReCaptchaValid
        });
    }

    //get user account
    const checkIsHelpValid = async (newHelpCriteria) => {
        const isOperatorNameValid = newHelpCriteria.operatorNames.length > 0;
        const isRequestorNameValid = newHelpCriteria.requestorName.length > 0;

        //***************Begin - Email************//
        var validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        const emailMatchFound = newHelpCriteria.requestorEmail.search(validEmail);
        const isEmailValid = emailMatchFound > -1;
        //***************End - Email**************//

        setIsHelpValid({
            ...isHelpValid,
            operatorNames: isOperatorNameValid,
            requestorName: isRequestorNameValid,
            requestorEmail: isEmailValid
        });

        if (!isOperatorNameValid || !isRequestorNameValid || !isEmailValid) {
            setInvalidHelpText("Invalid check information provided.  Please correct and try again.");
        }
        else {
            setInvalidHelpText("");
        }
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Cancel":
                console.log('cancelling...');
                break;
            case "Save":
                console.log('saving...');
                await registerUser();
                setIsRegisterClicked(true);
                //window.location.replace("https://totdata.com");                
                break;
            case "Help":
                console.log('sending...');

                setIsHelpClicked(true);
                setIsHelpDirty(false);

                if (invalidHelpText == "") {
                    await sendHelpOperatorRequest();
                }
        }
    }

    //register user
    const registerUser = async () => {
        console.log('Saving profile...');
        setDataStatus('loading');

        setInvalidText("");

        captchaRef.current.reset();
        //console.log('Reading ReCAPTCHA Token: ' + dataCriteria.reCaptchaToken);

        const newDataCriteria = {
            ...dataCriteria,
            checkAmount: dataCriteria.checkAmount.replace("$", "").replace(",", "")
        };

        setIsDirty(false);

        await saveRegistration(newDataCriteria)
            .then(response => {
                if (response.success) {
                    setRegistrationStatus('success');
                    setRegisterModalShow(false);
                    setSureModalShow(true);
                    setAlertVariant('success');
                    setAlertMessage('Registration succeeded for ' + dataCriteria.email + '.  Please check your inbox for instructions to activate your account.');
                    setAlertShow(true);

                    console.log('Successfully linked profile');
                    setDataStatus('ready');
                }
                else {
                    switch (response.resultText) {
                        case 'BadCheck':
                            setInvalidText("Invalid check information provided.  Please correct and try again.");
                            break;
                        case 'BadOperator':
                            setIsDataValid({
                                ...isDataValid,
                                operatorName: false
                            });
                            setInvalidText("Invalid Operator name provided.  Please correct and try again.");
                            break;
                        case 'EmailExistsWithDifferentOwner':
                            setInvalidText("This email address is already registered to an existing entity.  Please contact us at support@totdata.com for assistance to add additional entities.");
                            break;
                        default:
                            setInvalidText("Oops, something went wrong.  Please try again.");
                            break;
                    }

                    const newNumTries = numTries + 1;
                    setNumTries(newNumTries);
                    //if (newNumTries == 3) {
                    if (newNumTries == 3) {
                        setRegistrationStatus('fail');
                        setRegisterModalShow(false);
                        setSureModalShow(true);
                        setAlertVariant('danger');
                        setAlertMessage('Registration failed for ' + dataCriteria.email + ' using the information provided.  Please try again, or feel free to contact us at support@totdata.com.');
                        setAlertShow(true);
                    }

                    console.log('Failed to link profile');
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //clear reCAPTCHA token
                const updatedDataCriteria = {
                    ...dataCriteria,
                    reCaptchaToken: ''
                };

                setDataCriteria(updatedDataCriteria);
            })
    };
    
    //save user registration
    const saveRegistration = async (newCriteria) => {
        //console.log('Getting User Account...');

        const apiUrl = baseUrl + 'accounts/register';
        console.log(apiUrl);
        console.log(JSON.stringify(newCriteria));

        //const result = await fetch(apiUrl, {
        //    method: 'POST',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(newCriteria)
        //})
        //.then(response => {
        //    if (response.ok) {
        //        console.log('true response');
        //        return true;
        //    }
        //    else {
        //        return false;
        //    }
        //})
        //.catch(ex => {
        //    throw ex;                                   //use this to throw the raw error object
        //    //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //})

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));
        //return { success: result }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newCriteria)
        });

        //const registerResult = await response.text()
        //    .then(function (text) {
        //        if (text == "")
        //            return "blah";
        //        else if (text == "")
        //            return "yo";
        //        else
        //            return text;
        //});
        const registerResult = await response.text();

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));
        return { success: response.ok, resultText: registerResult }
    };
    
    //send help operator request
    const sendHelpOperatorRequest = async () => {
        console.log('Requesting info...');
        //setDataStatus('loading');

        await postHelpOperator()
            .then(response => {
                if (response.success) {
                    console.log('Successfully sent request');
                    setHelpResultStatus('success');
                    setHelpResultText("Thank you.  We'll investigate and get back to you as soon as possible.");
                }
                else {
                    //TODO: figure out what to do here
                    console.log('Failed to send request');
                    setHelpResultStatus('fail');
                    setHelpResultText("Oops, something went wrong.  Please feel free to try again, or contact us at support@totdata.com.");
                }

                setHelpResultModalShow(true);
                setHelpModalShow(false);

                setTimeout(() => {
                    setHelpResultModalShow(false);
                }, 5000);
            })
            .catch(ex => {
                //setDataStatus('error');
                //setDataErrors(ex.message);
                console.log('Error: ' + ex.message);
                setHelpResultStatus('fail');
                setHelpResultText("Oops, something went wrong.  Please feel free to try again, or contact us at support@totdata.com.");
            })
            .finally(response => {
                //do something
            })
    }

    //send help operator request
    const postHelpOperator = async () => {
        //console.log('Posting request info...');

        const apiUrl = baseUrl + 'a_email/helpoperator';
        console.log(apiUrl);

        var jsonData = {
            "name": helpCriteria.requestorName,
            "company": helpCriteria.operatorNames,
            "email": helpCriteria.requestorEmail
        }
        console.log(jsonData);

        const result = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonData)
        })
            .then(response => {
                if (response.ok) {
                    console.log('true response');
                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(ex => {
                throw ex;                                   //use this to throw the raw error object
                //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
            })

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: result }
    };

    //handle complete registration
    const handleCompleteRegistration = () => {
        window.location.href = "https://totdata.com";
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render register form
    const showRegisterForm = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4 shadow" style={{border: 'none', borderRadius: '10px'}} >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.75rem', fontWeight: '600' }} >Register</div>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ marginTop: '0px'}} >
                                    <hr />
                                    <div style={{ fontSize: '1.2rem', fontWeight: '600' }} >Enter the criteria below to register and link to your documents.</div>
                                </div>                                
                            </Row>
                            <Form noValidate>
                                <Form.Group as={Row} className="mb-3" controlId="region" style={{ marginTop: '30px' }}>
                                    <Form.Label column sm={3}>Operator's Region</Form.Label>
                                    <Col sm={4}>
                                        <Form.Select name="region" size="sm" value={dataCriteria.region} aria-label="Operator's Region" onChange={handleChange} >
                                            {regions.map((region, i) => (
                                                <option key={i} value={region.id}>{region.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="docType">
                                    <Form.Label column sm={3}>Document Type</Form.Label>
                                    <Col sm={4}>
                                        <Form.Select name="docType" size="sm" value={dataCriteria.docType} aria-label="Document Type" onChange={handleChange} >
                                            {documentTypes.map((docType, i) => (
                                                <option key={i} value={docType.id}>{docType.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="operatorName">
                                    <Form.Label column sm={3}>Operator Name</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="operatorName" size="sm" aria-label="Operator Name" value={dataCriteria.operatorName} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.operatorName : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter first 3 letters of operator's name.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="ownerNumber">
                                    <Form.Label column sm={3}>Owner Number</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={dataCriteria.ownerNumber} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.ownerNumber : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter owner number.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="checkMonth">
                                    <Form.Label column sm={3}>Check/ACH Month</Form.Label>
                                    <Col sm={2}>
                                        <Form.Select name="checkMonth" size="sm" value={dataCriteria.checkMonth} aria-label="Check Month" onChange={handleChange} >
                                            {months.map((month, i) => (
                                                <option key={i} value={month.index}>{month.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Select name="checkYear" size="sm" value={dataCriteria.checkYear} aria-label="Check Year" onChange={handleChange} >
                                            {years.map((year, i) => (
                                                <option key={i} value={year.year.num}>{year.year.num}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="checkAmount">
                                    <Form.Label column sm={3}>Check Amount</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="checkAmount" size="sm" aria-label="Check Amount" value={dataCriteria.checkAmount} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.checkAmount : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter check amount.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="email">
                                    <Form.Label column sm={3}>Email</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="email" size="sm" aria-label="Email" value={dataCriteria.email} onChange={handleChange} placeholder="john.doe@gmail.com" isInvalid={(isRegisterClicked == true ? !isDataValid.email : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmEmail">
                                    <Form.Label column sm={3}>Confirm Email</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="confirmEmail" size="sm" aria-label="Confirm Email" value={dataCriteria.confirmEmail} onChange={handleChange} placeholder="john.doe@gmail.com" isInvalid={(isRegisterClicked == true ? !isDataValid.confirmEmail : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Emails must match.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmTOC" style={{marginTop: '30px'}} >
                                    <Col sm={5}>
                                        <Form.Check
                                            type="checkbox"
                                            name="confirmTOC"
                                            label="Confirm Terms and Conditions"
                                            checked={dataCriteria.confirmTOC}
                                            onChange={handleCheckboxClick}
                                            feedback="You must confirm before submitting."
                                            feedbackType="invalid"
                                            isInvalid={(isRegisterClicked == true ? !isDataValid.confirmTOC : false)}

                                        />
                                    </Col>
                                </Form.Group>
                                <Row style={{ marginTop: '-10px' }} >
                                    <div>I authorize TOTData.com and its designated agents and representatives to use my information to protect against or prevent actual or potential fraud, unauthorized transactions or access, or other liability.</div>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            ref={captchaRef}
                                            onChange={handleReCaptchaChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Form.Group className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='md'
                                                style={{width: '400px'}}
                                                disabled={!isDirty}
                                                onClick={() => handleFormButtons('Save')}
                                            >
                                                Register
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '15px' }} >
                                    <Col className="d-flex justify-content-center">
                                        <div style={{ fontSize: '1rem', fontWeight: '600' }} ><i>Note: This is a one-time process to securely link your login profile to your documents.</i></div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    return (
      <div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        //return showRegisterForm() 
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <RegisterModal
                show={registerModalShow}
                onHide={() => handleHideRegisterModal()}
                dataCriteria={dataCriteria}
                handleCheckboxClick={handleCheckboxClick}
                handleReCaptchaChange={handleReCaptchaChange}
                handleFormButtons={handleFormButtons}
                helpButtonClick={() => handleShowHelpModal()}
                isRegisterClicked={isRegisterClicked}
                isDataValid={isDataValid}
                months={months}
                years={years}
                handleChange={handleChange}
                documentTypes={documentTypes}
                regions={regions}
                captchaRef={captchaRef}
                isDirty={isDirty}
                invalidText={invalidText}
                dataStatus={dataStatus}
                numTries={numTries}
            />

            <HelpModal
                show={helpModalShow}
                onHide={() => setHelpModalShow(false)}
                helpCriteria={helpCriteria}
                handleFormButtons={handleFormButtons}
                isHelpClicked={isHelpClicked}
                isHelpValid={isHelpValid}
                handleHelpDataChange={handleHelpDataChange}
                isHelpDirty={isHelpDirty}
                invalidHelpText={invalidHelpText}
                dataStatus={dataStatus}
            />

            <RegisterResultModal
                show={sureModalShow}
                onHide={handleCompleteRegistration}
                invalidText={invalidText}
                alertShow={alertShow}
                registrationStatus={registrationStatus}
                setAlertShow={setAlertShow}
                alertVariant={alertVariant}
                alertMessage={alertMessage}
            />

            <HelpResultModal
                show={helpResultModalShow}
                onHide={() => setHelpResultModalShow(false)}
                helpResultStatus={helpResultStatus}
                helpResultText={helpResultText}
            />
      </div>
    );
}

export default Register;
