import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Card, Modal, OverlayTrigger , Popover} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
//import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
//import spinnerIcon from 'src/assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPost } from "../services/AuthService.js";
import { Document, Page, pdfjs } from 'react-pdf'
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function NotificationModal(props) {
    const navigate = useNavigate();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Registration Notification Options</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h5>
                                <u>STEP 1: Select Notification Type</u>
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '16px'}} >                        
                        <Col sm={6} style={{ borderRight: '2px solid lightgray' }} >
                            <h5>
                                Download PDF Document
                                <OverlayTrigger placement="right" overlay={popoverPdf}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer', marginLeft: '10px', width: '20px', height: '20px' }} />
                                </OverlayTrigger>
                            </h5>                            
                        </Col>
                        <Col sm={6} style={{paddingLeft: '20px'}} >
                            <h5>
                                Send Emails with Link to Register
                                <OverlayTrigger placement="right" overlay={popoverEmail}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer', marginLeft: '10px', width: '20px', height: '20px' }} />
                                </OverlayTrigger>
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: '20px'}} >
                        <Col sm={6} style={{ borderRight: '2px solid lightgray' }}>
                            <Form.Check
                                type="radio"
                                name="notifyType"
                                id="pdf"
                                checked={props.notifyType == 'pdf'}
                                style={{ marginTop: '10px' }}
                                label="Download PDF Document"
                                onClick={(e) => props.handleNotifyType(e)}
                            />
                            <Form.Check
                                type="checkbox"
                                name="includeStatements"
                                id="chkIncludeStatements"
                                checked={props.includeStatements}
                                style={{ marginLeft: '20px' }}
                                label="Include Statements"
                                disabled={props.notifyType != 'pdf'}
                                onChange={(e) => props.setIncludeStatements(e.target.checked)}
                            />
                        </Col>
                        <Col sm={6} style={{ paddingLeft: '20px' }} >
                            <Form.Check
                                type="radio"
                                name="notifyType"
                                id="email"
                                checked={props.notifyType == 'email'}
                                style={{ marginTop: '10px' }}
                                label="Send Emails with Link to Register"
                                onClick={(e) => props.handleNotifyType(e)}
                            />
                            {(() => {
                                if (props.allLastSentDate != null) {
                                    return <p style={{ marginTop: '0px', paddingTop: '0px', marginLeft: '25px' }} ><i><b>All last sent: {props.allLastSentDate}</b></i></p>
                                }
                            })()}
                        </Col>                        
                    </Row>
                    <Row style={{ borderTop: '2px solid lightgray', paddingTop: '20px' }}>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h5>
                                <u>STEP 2: Select Owners</u>
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '16px' }} >                        
                        <Col>
                            <h5>
                                Unregistered Owners Selection
                                <OverlayTrigger placement="right" overlay={popoverSelect}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer', marginLeft: '10px', width: '20px', height: '20px' }} />
                                </OverlayTrigger>
                            </h5>
                        </Col>
                    </Row>
                    {(() => {
                        if (props.unregisteredOwnersCount == 0) {
                            return <>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p style={{ color: 'red', fontWeight: 'bold', marginTop: '10px', marginBottom: '0px' }} >Great news! All Owners in this check data file are already registered on totdata.com. These Owners will automatically receive emails each month when statements are sent.</p>
                                    </Col>
                                </Row>
                            </>
                        }
                        else {
                            return <>
                                <Row>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            name="ownerOption"
                                            id="all"
                                            inline
                                            disabled={props.modalDataStatus != 'ready'}
                                            checked={props.ownerOption == 'all'}
                                            style={{ marginTop: '20px' }}
                                            label="Select All"
                                            onClick={(e) => props.handleOwnerOption(e)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="ownerOption"
                                            id="custom"
                                            inline
                                            disabled={props.modalDataStatus != 'ready'}
                                            checked={props.ownerOption == 'custom'}
                                            style={{ marginTop: '10px' }}
                                            label="Select from list..."
                                            onClick={(e) => props.handleOwnerOption(e)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                    })()}
                    <p style={{ color: 'red', marginBottom: '0' }} hidden={props.notifyType == 'pdf' || props.unregisteredOwnersCount == 0 || (props.notifyType == 'email' && props.unregisteredOwners.filter(o => (o.ownerEmail ?? '') != '').length > 0)} >
                        <b>There are no unregistered Owners for which an email address has been provided.  Owner email addresses that were not provided previously may be added via the "Load Email Addresses" link under Settings | Owner Mappings, or from the link below:</b>
                    </p>
                    <p style={{ color: 'red', marginTop: '0' }} hidden={props.notifyType == 'pdf' || props.unregisteredOwnersCount == 0 || (props.notifyType == 'email' && props.unregisteredOwners.filter(o => (o.ownerEmail ?? '') != '').length > 0)} >
                        <Button id="uploadEmails" variant="link"
                            size='md'
                            onClick={() => navigate('/uploademails')}
                        >
                            Load Email Addresses
                        </Button>                        
                    </p>
                    {(() => {
                        if (props.ownersPopulated && props.unregisteredOwnersCount > 0) {
                            return <>                                
                                <Row style={{marginTop: '10px'}} >
                                    <Col>
                                        <div style={{ height: "200px", overflowY: "scroll", border: '2px solid lightgray', borderRadius: '5px' }}>
                                            <table className="table" aria-labelledby="tabelLabel" >
                                                <thead style={{ position: 'sticky', top: '0px', margin: '0 0 0 0', backgroundColor: 'whitesmoke', }} >
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Owner Number</th>
                                                        <th>Owner Name</th>
                                                        <th>Owner Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.unregisteredOwners.map((owner) => (
                                                        <tr key={owner.ownerNumber} style={{ display: (props.notifyType == 'email' && (owner.ownerEmail ?? '') == '') ? 'none' : 'table-row' }}>
                                                            <td style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="chkOwner"
                                                                    checked={!!owner.isChecked}
                                                                    style={{  }}
                                                                    label=""
                                                                    onChange={() => props.handleOwnerCheck(owner.ownerId)}
                                                                />
                                                            </td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{owner.ownerNumber}</td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{owner.ownerName}</td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{(owner.ownerEmail ?? '') == '' ? '' : owner.ownerEmail}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    })()}
                </Container>
            </Modal.Body>
            <Modal.Footer>                
                <Container>
                    <Row>
                        <Col>
                            <Button style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'right' }} >
                        <Button
                            type='button'
                            size='sm'
                            style={{ width: '225px' }}
                                disabled={props.unregisteredOwnersCount == 0 || !props.anyOwnerSelected || props.modalDataStatus != 'ready'}
                            onClick={() => props.handleNextButton()}
                        >
                                Review and Confirm &gt;&gt;
                        </Button>
                        </Col>                        
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function ConfirmationModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Review and Confirm</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {(() => {
                        if (props.dataStatus == 'loading' && props.isPreview) {
                            return <>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SpinnerComponent />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center' }} >
                                            <h4>Generating preview...</h4>
                                            <p>Please be patient, this might take a minute or two.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        else if (props.dataStatus == 'loading' && !props.isPreview) {
                            return <>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SpinnerComponent />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center' }} >
                                            <h4>Requesting notification...</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        else {
                            return <>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <h5>{props.notifyType == "email" ? "Send Emails with Link to Register" : "Download PDF Document"}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p>{props.notifyType == "email" ? "Send email with link to register at totdata.com to each owner below:" : (props.includeStatements == true ? "Download PDF document with registration letters (including statements) for each Owner below:" : "Download PDF document with registration letters for each Owner below:")}</p>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }} >
                                    <Col>
                                        <div style={{ height: "200px", overflowY: "scroll", border: '2px solid lightgray', borderRadius: '5px' }}>
                                            <table className="table" aria-labelledby="tabelLabel" >
                                                <thead style={{ position: 'sticky', top: '0px', margin: '0 0 0 0', backgroundColor: 'whitesmoke', }} >
                                                    <tr>
                                                        <th>Owner Number</th>
                                                        <th>Owner Name</th>
                                                        <th>Owner Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.unregisteredOwners.map((owner) => {
                                                        if (!!owner.isChecked) {
                                                            return <tr key={owner.ownerNumber} style={{ display: (props.notifyType == 'email' && (owner.ownerEmail ?? '') == '') ? 'none' : 'table-row' }}>
                                                                <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{owner.ownerNumber}</td>
                                                                <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{owner.ownerName}</td>
                                                                <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{(owner.ownerEmail ?? '') == '' ? '' : owner.ownerEmail}</td>
                                                            </tr>
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    })()}                    
                    {(() => {
                        if (props.dataStatus != 'loading' && props.notifyType == "pdf") {
                            return <Row style={{marginTop: '16px'}} >
                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>Note: An email with a link to download the PDF document will be sent to {props.userEmail} when ready.  Please be patient, as it may take up to an hour or more to generate the document, depending on the number of Owners selected.</p>
                                </Col>
                            </Row>
                        }
                    })()}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={props.onHide}
                                disabled={props.dataStatus == 'loading'}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'right' }} >
                            <Button
                                type='button'
                                size='sm'
                                style={{ width: '175px' }}
                                disabled={props.dataStatus == 'loading'}
                                onClick={() => props.handleBackButton()}
                            >
                                &lt;&lt; Go Back
                            </Button>
                            <Button
                                type='button'
                                size='sm'
                                style={{ marginLeft: '20px', width: '175px' }}
                                disabled={props.dataStatus == 'loading'}
                                onClick={() => props.handlePreviewButton(true)}
                            >
                                Preview Sample
                            </Button>
                            <Button
                                type='button'
                                size='sm'
                                style={{ marginLeft: '20px', width: '175px' }}
                                disabled={props.dataStatus == 'loading'}
                                onClick={() => props.displaySureModal()}
                            >
                                {props.notifyType == "email" ? "Send Emails" : "Download PDF"}
                            </Button>
                        </Col>
                    </Row>                   
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function DisplayPdfModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Preview</h4>
                    <h6>The document below is a preview of the first few pages.  The generated document will contain pages for all selected owners.</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{height: '700px'}} >
                    <Row>
                        <Col style={{display: 'flex', justifyContent: 'center'}}>
                            {(() => {
                                if (props.blobContent) {
                                    return <div>
                                            <Document file={window.URL.createObjectURL(props.blobContent)} onLoadSuccess={props.onDocumentLoadSuccess}>
                                            <Page pageNumber={props.pdfPageNumber}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                scale={1.25}
                                            />
                                            </Document>
                                        </div>
                                }
                            })()}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>                    
                    {(() => {
                        if (props.blobContent) {
                            return <>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p>
                                            Page {props.pdfPageNumber || (props.pdfNumPages ? 1 : "--")} of {props.pdfNumPages || "--"}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            type='button'
                                            size='sm'
                                            style={{ width: '100px' }}
                                            disabled={props.pdfPageNumber <= 1}
                                            onClick={props.handlePdfPrevPage}
                                        >
                                            &lt;&lt; Previous
                                        </Button>
                                        <Button
                                            type='button'
                                            size='sm'
                                            style={{ width: '100px', marginLeft: '10px' }}
                                            disabled={props.pdfPageNumber >= props.pdfNumPages}
                                            onClick={() => props.handlePdfNextPage()}
                                        >
                                            Next &gt;&gt;
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    })()}
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function SureModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton style={{backgroundColor: 'lightgray'}} >
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>Are you sure?</h4>
                        </Col>
                    </Row>
                    {(() => {
                        if (props.allLastSentDate != null) {
                            return <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p style={{ marginTop: '0px', paddingTop: '0px' }} ><i><b>Emails were last sent to all unregistered Owners on: {props.allLastSentDate}</b></i></p>
                                </Col>
                            </Row>
                        }
                    })()}
                    <Row style={{marginTop: '20px'}} >
                        <Col style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button
                                onClick={props.onHide}
                                style={{ width: '100px', backgroundColor: 'red' }}
                            >
                                No
                            </Button>
                            <Button
                                type='button'
                                size='sm'
                                style={{ marginLeft: '20px', width: '100px', backgroundColor: 'green' }}
                                onClick={() => props.handleSendButton(false)}
                            >
                                Yes
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const popoverPdf = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Download PDF Document</Popover.Header>
        <Popover.Body>
            <p>Download a printable PDF document with letters for each unregistered Owner selected below.</p>
            <ul>
                <li>
                    The letters provide instructions on how to register for an account on the TOTData website, where Owners can download their current and future statements.
                </li>
                <li style={{ marginTop: '5px' }} >
                    Optionally, you may choose to include the Owners' current statements in the PDF document.
                </li>
            </ul>
        </Popover.Body>
    </Popover>
);

const popoverEmail = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Send Emails with Link to Register</Popover.Header>
        <Popover.Body>
            <p>Send an email to each unregistered Owner selected below.</p>
            <ul>
                <li>
                    The email includes a link to register for an account on the TOTData website, where Owners can download their current and future statements.
                </li>
            </ul>
        </Popover.Body>
    </Popover>
);

const popoverSelect = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Unregistered Owners Selection</Popover.Header>
        <Popover.Body>
            <p>Select the unregistered Owners for whom you wish to include letters (and statements, if chosen above) in the PDF document, or to whom you wish to send emails.</p>
            <ul>
                <li style={{marginTop: '5px'}}>
                    Owners who are already registered on totdata.com are not included.
                </li>
                <li style={{ marginTop: '5px' }}>
                    For Email Notifications, Owners for which an email address has not been provided are not included.
                </li>
                <ul>
                    <li style={{marginTop: '5px'}} >
                        Owner email addresses that were not provided previously may be added through the "Load Email Addresses" link under Settings | Owner Mappings.
                    </li>
                </ul>
            </ul>
        </Popover.Body>
    </Popover>
);

const Statements = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently, user } = useAuth0();
    
    //stateful properties (hooks)
    const params = useParams();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [modalDataStatus, setModalDataStatus] = useState('ready');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [checkStatements, setCheckStatements] = useState([]);
    const [dataFileReleased, setDataFileReleased] = useState(false);
    const [notifyType, setNotifyType] = useState('pdf');  //or 'pdf'
    const [includeStatements, setIncludeStatements] = useState(false);
    const [ownerOption, setOwnerOption] = useState('all');  //or 'custom'
    const [unregisteredOwners, setUnregisteredOwners] = useState([]);
    const [unregisteredOwnersCount, setUnregisteredOwnersCount] = useState(0);
    const [allLastSentDate, setAllLastSentDate] = useState(null);
    const [ownersPopulated, setOwnersPopulated] = useState(false);
    const [anyOwnerSelected, setAnyOwnerSelected] = useState(false);
    const [checkStatusItems, setCheckStatusItems] = useState([{ id: 0, status: '' }, { id: 1, status: 'Released' }, { id: 2, status: 'Downloaded' }]);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: params.id,
        skipCount: 0,
        takeCount: 50,
        ownerNumber: "",
        ownerName: "",
        checkNumber: "",
        checkStatusCriteriaId: 0
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [screenHeight, setScreenHeight] = useState(100);
    
    const [notifyModalShow, setNotifyModalShow] = React.useState(false);
    const [confirmModalShow, setConfirmModalShow] = React.useState(false);
    const [blobContent, setBlobContent] = React.useState(null);
    const [displayPdfModalShow, setDisplayPdfModalShow] = React.useState(false);
    const [sureModalShow, setSureModalShow] = React.useState(false);
    const [pdfPageNumber, setPdfPageNumber] = React.useState(1);
    const [pdfNumPages, setPdfNumPages] = React.useState(false);
    const [isPreview, setIsPreview] = React.useState(false);
    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('Notification emails sent!');

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //get inner browser window height
            const height = window.innerHeight;
            setScreenHeight(height);

            await populateCheckStatements(0)
                .then(response => {
                    if (response.data.length === 0) {
                        console.log(response.data);
                        setDataStatus('nodata')
                    }
                    else {
                        //console.log('Check Count: ' + response.data[0].rowCount);
                        //console.log('DataFileReleased: ' + response.data[0].dataFileReleased);
                        setRowCount(response.data[0].rowCount);
                        setCheckStatements(response.data);
                        setDataFileReleased(response.data[0].dataFileReleased);
                        setDataStatus('ready')
                    }
                    
                    setPageStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, [window.innerHeight]);

    //populate check statements
    const populateCheckStatements = async (skipCount) => {
        //console.log('Getting Check Statements...');

        const apiUrl = 'datafiles/' + dataCriteria.dataFileId + '/checkstatements?skipcount=' + skipCount + '&takecount=' + dataCriteria.takeCount + (dataCriteria.ownerNumber === "" ? '' : '&ownerNumber=' + dataCriteria.ownerNumber) + (dataCriteria.ownerName === "" ? '' : '&ownerName=' + dataCriteria.ownerName) + (dataCriteria.checkNumber === "" ? '' : '&checkNumber=' + dataCriteria.checkNumber) + (dataCriteria.checkStatusCriteriaId === '0' ? '' : '&checkStatusCriteriaId=' + dataCriteria.checkStatusCriteriaId);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    const downloadPdf = async (exportType, checkId) => {
        try {
            setDataStatus('loading');

            var selectedStatement = checkStatements.filter(function (statement) {
                return statement.checkId === parseInt(checkId);
            })[0];

            let fileName = selectedStatement.fileNamePrefix + '.';

            //let apiUrl = baseUrl + 'a_export/';
            let apiUrl = 'a_export/';
            switch (exportType) {
                case 'Pdf':
                    apiUrl = 'a_report/pdfreport';
                    fileName = fileName + 'pdf';
                    break;
                case 'Excel':
                    apiUrl = apiUrl + 'excel';
                    fileName = fileName + 'xlsx';
                    break;
                case 'Cdex':
                    apiUrl = apiUrl + 'cdex';
                    fileName = fileName + 'dat';
                    break;
                default:
                    break;
            }

            apiUrl = apiUrl + '?checkid=' + checkId + '&flaggenerated=false';
            //console.log(apiUrl);

            await getDownloadDocument(apiUrl).then(response => {
                if (response.success) {
                    console.log('Response was success');
                    response.data.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = fileName;
                        alink.click();

                        setAlertVariant('success');
                        setAlertMessage('Successfully downloaded the document.');
                        setAlertShow(true);
                        setTimeout(() => {
                            setAlertShow(false);
                        }, 5000);
                        setDataStatus('ready');
                    })
                } else {
                    console.log('Response was failed: ' + response.data.statusText);
                    setAlertVariant('danger');
                    setAlertMessage('An error occurred while generating the document.');
                    setAlertShow(true);
                    setTimeout(() => {
                        setAlertShow(false);
                    }, 5000);
                    setDataStatus('ready');
                }
            });
        } catch (ex) {
            console.log('Response was error');
            console.log(ex.message);
            setDataStatus('error');
            setDataErrors(ex.message);
        }        
    };

    const handleSendButton = async (isPreview) => {
        try {
            setDataStatus('loading');
            setIsPreview(isPreview);
            setNotifyModalShow(false);
            setConfirmModalShow(false);
            setSureModalShow(false);

            //console.log('Type: ' + notifyType + ', Include: ' + includeStatements + , 'IsPreview: ' + isPreview);

            let selectedOwners = null;
            let unselectedOwners = null;
            let ownerIdList = null;
            let body = null;
            let allUnregisteredOwners = null;

            let apiUrl = 'a_export/';
            switch (notifyType) {
                case 'email':

                    selectedOwners = unregisteredOwners.filter(o => o.isChecked === true && (o.ownerEmail ?? '') != '');
                    unselectedOwners = unregisteredOwners.filter(o => o.isChecked === false && (o.ownerEmail ?? '') != '');
                    ownerIdList = selectedOwners.map((owner) => {
                        if (!!owner.isChecked && (owner.ownerEmail ?? '') != '') {
                            return owner.ownerId;
                        }
                    });
                    body = { dataFileId: dataCriteria.dataFileId, ownerIdList: ownerIdList };

                    //set allUnregisteredOwners parameter below
                    allUnregisteredOwners = unselectedOwners.length === 0;

                    //set api url
                    apiUrl = apiUrl + dataCriteria.dataFileId + '/requestregistrationemail';
                    apiUrl = apiUrl + '?includeStatements=false' + '&allUnregisteredOwners=' + allUnregisteredOwners + '&isPreview=' + isPreview; 

                    //console.log(apiUrl);

                    await getPreviewDocument(apiUrl, body).then(response => {
                        if (response.success) {
                            if (isPreview) {
                                response.data.blob().then(blob => {
                                    setBlobContent(blob);
                                    setDisplayPdfModalShow(true);
                                }
                            )}
                            //
                            console.log('Response was success');
                            console.log('Email notifications sent');
                            setDataStatus('ready');
                            setAlertVariant('success');
                            setAlertMessage('Email notifications requested successfully.');
                        } else {
                            setAlertVariant('danger');
                            setAlertMessage('Email notifications request failed.');
                        }

                        setAlertShow(true);
                    })
                    .finally(response => {
                        setTimeout(() => {
                            setAlertShow(false);
                        }, 5000);

                    })
                    break;
                case 'pdf':

                    selectedOwners = unregisteredOwners.filter(o => o.isChecked === true);
                    unselectedOwners = unregisteredOwners.filter(o => o.isChecked === false);
                    ownerIdList = selectedOwners.map((owner) => {
                        if (!!owner.isChecked) {
                            return owner.ownerId;
                        }
                    });
                    body = { dataFileId: dataCriteria.dataFileId, ownerIdList: ownerIdList };

                    //set allUnregisteredOwners parameter below
                    allUnregisteredOwners = unselectedOwners.length === 0;

                    //set api url
                    apiUrl = apiUrl + dataCriteria.dataFileId + '/requestregistrationpdf';
                    apiUrl = apiUrl + '?includeStatements=' + includeStatements + '&allUnregisteredOwners=' + allUnregisteredOwners + '&isPreview=' + isPreview;
                    //console.log(apiUrl);

                    let fileName = 'RevenueStatement.pdf';
                    await getPreviewDocument(apiUrl, body).then(response => {
                        if (response.success) {
                            console.log('Response was success');
                            response.data.blob().then(blob => {
                                if (isPreview) {
                                    setBlobContent(blob);
                                    setDisplayPdfModalShow(true);
                                } else {
                                    //// Creating new object of PDF file
                                    //const fileURL = window.URL.createObjectURL(blob);
                                    //// Setting various property values
                                    //let alink = document.createElement('a');
                                    //alink.href = fileURL;
                                    //alink.download = fileName;
                                    //alink.click();
                                }

                                console.log('Pdf notifications generated');
                                setDataStatus('ready');
                                setAlertVariant('success');
                                setAlertMessage('PDF document download requested successfully.');
                            })
                        } else {
                            setAlertVariant('danger');
                            setAlertMessage('PDF document download request failed.');
                        }

                        setAlertShow(true);
                    })
                    .finally(response => {
                        setTimeout(() => {
                            setAlertShow(false);
                        }, 5000);

                    })
                    break;
                default:
                    break;
            }
            
        } catch (ex) {
            console.log(ex.message);
            setDataStatus('error');
            setDataErrors(ex.message);
        }

        //setNotifyType('email');
        //setIncludeStatements(false);
    };

    const handlePreviewButton = async (isPreview) => {
        try {
            setDataStatus('loading');
            setIsPreview(isPreview);
            setNotifyModalShow(false);

            //console.log('Type: ' + notifyType + ', Include: ' + includeStatements + , 'IsPreview: ' + isPreview);

            let selectedOwners = null;
            let unselectedOwners = null;
            let ownerIdList = null;
            let body = null;
            let allUnregisteredOwners = null;

            let apiUrl = 'a_export/';
            switch (notifyType) {
                case 'email':

                    selectedOwners = unregisteredOwners.filter(o => o.isChecked === true && (o.ownerEmail ?? '') != '');
                    unselectedOwners = unregisteredOwners.filter(o => o.isChecked === false && (o.ownerEmail ?? '') != '');
                    ownerIdList = selectedOwners.map((owner) => {
                        if (!!owner.isChecked && (owner.ownerEmail ?? '') != '') {
                            return owner.ownerId;
                        }
                    });
                    body = { dataFileId: dataCriteria.dataFileId, ownerIdList: ownerIdList };

                    //set allUnregisteredOwners parameter below
                    allUnregisteredOwners = unselectedOwners.length === 0;

                    //set api url
                    apiUrl = apiUrl + dataCriteria.dataFileId + '/requestregistrationemail';
                    apiUrl = apiUrl + '?includeStatements=false' + '&allUnregisteredOwners=' + allUnregisteredOwners + '&isPreview=' + isPreview; 

                    //console.log(apiUrl);

                    await getPreviewDocument(apiUrl, body).then(response => {
                        if (response.success) {
                            if (isPreview) {
                                response.data.blob().then(blob => {
                                    setBlobContent(blob);
                                    setDisplayPdfModalShow(true);
                                }
                                )
                            }
                            //
                            console.log('Response was success');
                            console.log('Email notifications sent');
                            setDataStatus('ready');
                            setAlertVariant('success');
                            setAlertMessage('Email notifications requested successfully.');
                        } else {
                            setAlertVariant('danger');
                            setAlertMessage('Email notifications request failed.');
                        }
                    });
                    break;
                case 'pdf':

                    selectedOwners = unregisteredOwners.filter(o => o.isChecked === true);
                    unselectedOwners = unregisteredOwners.filter(o => o.isChecked === false);
                    ownerIdList = selectedOwners.map((owner) => {
                        if (!!owner.isChecked) {
                            return owner.ownerId;
                        }
                    });
                    body = { dataFileId: dataCriteria.dataFileId, ownerIdList: ownerIdList };

                    //set allUnregisteredOwners parameter below
                    allUnregisteredOwners = unselectedOwners.length === 0;

                    //set api url
                    apiUrl = 'a_report/';
                    apiUrl = apiUrl + dataCriteria.dataFileId + '/requestregistrationpdf';
                    apiUrl = apiUrl + '?includeStatements=' + includeStatements + '&allUnregisteredOwners=' + allUnregisteredOwners + '&isPreview=' + isPreview;
                    console.log(apiUrl);

                    //let fileName = 'RevenueStatement.pdf';
                    await getPreviewDocument(apiUrl, body).then(response => {
                        if (response.success) {
                            console.log('Response was success');
                            response.data.blob().then(blob => {
                                if (isPreview) {
                                    setBlobContent(blob);
                                    setDisplayPdfModalShow(true);
                                } else {
                                    //// Creating new object of PDF file
                                    //const fileURL = window.URL.createObjectURL(blob);
                                    //// Setting various property values
                                    //let alink = document.createElement('a');
                                    //alink.href = fileURL;
                                    //alink.download = fileName;
                                    //alink.click();
                                }

                                console.log('Pdf notifications generated');
                                setDataStatus('ready');
                                setAlertVariant('success');
                                setAlertMessage('PDF document download requested successfully.');
                            })
                        } else {
                            setAlertVariant('danger');
                            setAlertMessage('PDF document download request failed.');
                        }
                    });
                    break;
                default:
                    break;
            }

        } catch (ex) {
            console.log(ex.message);
            setDataStatus('error');
            setDataErrors(ex.message);
        }

        //setNotifyType('email');
        //setIncludeStatements(false);
    };

    //getDownloadDocument
    const getDownloadDocument = async (apiUrl, body) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //getPreviewDocument
    const getPreviewDocument = async (apiUrl, body) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);
        const jsonBody = JSON.stringify(body);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPost(apiUrl, jsonBody, true, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //processNotifications
    const processNotifications = async (apiUrl) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle modal notify type radio button changes
    const handleNotifyType = (event) => {
        //event.preventDefault();

        const fieldId = event.target.id;
        //console.log('Field: ' + fieldId);

        setNotifyType(fieldId);

        if (fieldId != 'pdf') {
            setIncludeStatements(false);
        }

        //set the anyOwnerSelected value
        if (fieldId == 'email') {
            const ownersWithEmail = unregisteredOwners.filter(owner => (owner.ownerEmail ?? '') != '' && !!owner.isChecked === true);
            setAnyOwnerSelected(ownersWithEmail.length > 0);
        }
        else {            
            const ownersChecked = unregisteredOwners.filter(owner => !!owner.isChecked === true);
            setAnyOwnerSelected(ownersChecked.length > 0);
        }
    }
    
    //handle modal owner option radio button changes
    const handleOwnerOption = async (event) => {
        //event.preventDefault();

        const fieldId = event.target.id;
        console.log('Field: ' + fieldId);

        setOwnerOption(fieldId);

        if (fieldId == 'custom') {
            if (!ownersPopulated) {
                setModalDataStatus('loading');
                await populateUnregisteredOwners(false)
                    .then(response => {
                        if (response.data.length === 0) {
                            //console.log(response.data);
                            setModalDataStatus('nodata')
                        }
                        else {
                            const newOwners = response.data.map(owner => {
                                return { ...owner, isChecked: false }
                            });
                            setUnregisteredOwners(newOwners);
                            setUnregisteredOwnersCount(newOwners.length);
                            setAllLastSentDate(newOwners[0].allLastSent);
                            setAnyOwnerSelected(false);

                            setModalDataStatus('ready')
                        }
                        setOwnersPopulated(true);
                    })
                    .catch(ex => {
                        setPageStatus('error');
                        setPageErrors(ex.message);

                        setModalDataStatus('error');
                        setDataErrors(ex.message);
                    })
                    .finally(response => {
                        //do something
                    })
            }
            else {
                //check or uncheck all owners
                const newOwners = unregisteredOwners.map(owner => {
                    return {
                        ...owner,
                        isChecked: false
                    };
                });
                setUnregisteredOwners(newOwners);

                //set the anyOwnerSelected value
                setAnyOwnerSelected(false);
            }
        }
        else {
            //check all owners
            const newOwners = unregisteredOwners.map(owner => {
                return {
                    ...owner,
                    isChecked: true
                };
            });
            setUnregisteredOwners(newOwners);

            //set the anyOwnerSelected value
            if (notifyType == 'email') {
                const ownersWithEmail = newOwners.filter(owner => (owner.ownerEmail ?? '') != '' && !!owner.isChecked === true);
                setAnyOwnerSelected(ownersWithEmail.length > 0);
            }
            else {
                const ownersChecked = newOwners.filter(owner => !!owner.isChecked === true);
                setAnyOwnerSelected(ownersChecked.length > 0);
            }
        }
    }

    //populate unregistered owners
    const populateUnregisteredOwners = async (rowCountOnly) => {
        //console.log('Getting Check Statements...');

        const apiUrl = 'datafiles/' + dataCriteria.dataFileId + '/unregisteredowners?rowCountOnly=' + rowCountOnly;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle modal owner checkbox changes
    const handleOwnerCheck = (ownerId) => {
        //event.preventDefault();
        console.log('OwnerId: ' + ownerId);

        const newOwners = unregisteredOwners.map(owner => {
            if (owner.ownerId === ownerId) {
                
                //get the current ownerChecked value
                const ownerChecked = !!owner.isChecked;

                //flip the bit on the owner's checkbox
                return {
                    ...owner,
                    isChecked: !ownerChecked
                };
            }
            else {
                return owner;
            }
        });

        //set the anyOwnerSelected value
        if (notifyType == 'email') {
            const ownersWithEmail = newOwners.filter(owner => (owner.ownerEmail ?? '') != '' && !!owner.isChecked === true);
            setAnyOwnerSelected(ownersWithEmail.length > 0);
        }
        else {
            const ownersChecked = newOwners.filter(owner => !!owner.isChecked === true);
            setAnyOwnerSelected(ownersChecked.length > 0);
        }

        //newOwners.map(newOwner => {
        //    console.log('New owner - OwnerId: ' + newOwner.ownerId + ', OwnerName:' + newOwner.ownerName + ', IsChecked: ' + !!newOwner.isChecked);
        //});

        setUnregisteredOwners(newOwners);
    }

    //handle modal cancel and hide buttons
    const handleHideModal = () => {
        setNotifyModalShow(false);
        setConfirmModalShow(false);
        setNotifyType('email');
        setIncludeStatements(false);
    }

    //handle modal display pdf hide button
    const handleHideDisplayPdfModal = () => {
        setDisplayPdfModalShow(false);
        setPdfPageNumber(1);
        setBlobContent(null);
    }

    //handle pdf document load success
    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log('load success');
        setPdfNumPages(numPages);
    }

    //handleSetPdfPageNumber
    const changePdfPage = (offset) => {
        setPdfPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    //handleSetPdfPageNumber
    const handlePdfPrevPage = () => {
        changePdfPage(-1);
    }

    //handleSetPdfPageNumber
    const handlePdfNextPage = () => {
        changePdfPage(1);
    }

    //handle modal next button
    const handleNextButton = async () => {       

        if (ownerOption == 'all' && !ownersPopulated) {
            setModalDataStatus('loading');
            await populateUnregisteredOwners(false)
                .then(response => {
                    if (response.data.length === 0) {
                        //console.log(response.data);
                        setModalDataStatus('nodata')
                    }
                    else {
                        const newOwners = response.data.map(owner => {
                            return { ...owner, isChecked: true }
                        });
                        setUnregisteredOwners(newOwners);
                        setUnregisteredOwnersCount(newOwners.length);
                        setAllLastSentDate(newOwners[0].allLastSent);
                        setAnyOwnerSelected(true);

                        setNotifyModalShow(false);
                        setConfirmModalShow(true);

                        setModalDataStatus('ready')
                    }
                    setOwnersPopulated(true);
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setModalDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        }
        else {
            setNotifyModalShow(false);
            setConfirmModalShow(true);
        }
    }

    //handle modal back button
    const handleBackButton = () => {
        setNotifyModalShow(true);
        setConfirmModalShow(false);
    }

    //submit form
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setCurrentPage(1);

        await populateCheckStatements(0)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }                
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle notify owners button
    const handleNotifyOwnersButton = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        //get unregistered owner count
        await populateUnregisteredOwners(false)
            .then(response => {
                if (response.success) {
                    if (response.data.length === 0) {
                        //console.log(response.data);

                        setUnregisteredOwners([]);
                        setUnregisteredOwnersCount(0);
                        //setUnregisteredOwnersCount(0);        //temp for testing
                        setAnyOwnerSelected(true);
                        setOwnerOption('all');
                        setNotifyType('pdf');
                        setIncludeStatements(false);

                        setModalDataStatus('ready');
                        setDataStatus('ready');
                        setNotifyModalShow(true);
                    }
                    else {
                        console.log('Unregistered Owner Count: ' + response.data[0].rowCount);
                        console.log('All Last Sent Date: ' + response.data[0].allLastSent);

                        const newOwners = response.data.map(owner => {
                            return { ...owner, isChecked: true }
                        });
                        setUnregisteredOwners(newOwners);                        
                        setUnregisteredOwnersCount(newOwners.length);
                        //setUnregisteredOwnersCount(0);        //temp for testing
                        setAllLastSentDate(newOwners[0].allLastSent);
                        setAnyOwnerSelected(true);
                        setOwnerOption('all');
                        setNotifyType('pdf');
                        setIncludeStatements(false);

                        setModalDataStatus('ready');
                        setDataStatus('ready');
                        setNotifyModalShow(true);
                    }
                    setOwnersPopulated(true);
                }
                else {
                    //console.log(response.data);
                    setDataStatus('nodata')
                    setModalDataStatus('nodata')
                }
            })
            .catch(ex => {
                setPageStatus('error');
                setPageErrors(ex.message);

                setModalDataStatus('error');
                setDataStatus('error')
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            });
    };

    //handle clear button
    const handleClearButton = async () => {

        setDataCriteria({
            ...dataCriteria,
            ownerNumber: '',
            ownerName: '',
            checkNumber: '',
            checkStatusCriteriaId: 0
        });
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);
        
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        //setDataStatus('loading');

        await populateCheckStatements(skipCount)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    ////render page loading
    ////note: spinner overlay will eventually be at the page level (I think)
    //const showPageLoading = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    ////render page loading
    //const showDataLoading = () => {
    //    return (
    //        <Row>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </Row>
    //    );
    //}

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{height: (screenHeight-400).toString() + 'px', overflow: "auto"}} >
                {(() => {
                    switch (dataStatus) {
                        case 'nodata':
                            return <p>No Data Loaded.</p>
                        case 'loading':
                            return showDataLoading()     
                        case 'ready':
                            return showData()
                        case 'error':
                            return showDataError()
                        default:
                            return <Row>No Data Loaded.</Row>
                    }
                    })()}
                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'lightgray' }}>
                    <Container fluid style={{ height: '40px' }} >
                        {(() => {
                            if (dataStatus == 'ready') {
                                return <Row>
                                    <Col className="d-flex justify-content-end">
                                        <PaginationComponent
                                            itemsCount={rowCount}
                                            itemsPerPage={dataCriteria.takeCount}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageClickHandler={handlePageClick}
                                            alwaysShown={true}
                                        />
                                    </Col>
                                </Row>
                            }
                        })()}
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render check statements
    const showData = () => {
        return (
            <Container fluid>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Co #</th>
                                <th>Owner Number</th>
                                <th>Owner Name</th>
                                <th>Owner Status</th>
                                <th>Check Number</th>
                                <th>Check Amount</th>
                                <th>Check Date</th>
                                <th colSpan={3} style={{ textAlign: "center" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {checkStatements.map((checkStatement) => (
                                <tr key={checkStatement.checkId}>
                                    <td>{checkStatement.companyNumber}</td>
                                    <td>{checkStatement.ownerNumber}</td>
                                    <td>{checkStatement.ownerName}</td>
                                    <td>{(!checkStatement.dataFileReleased ? "Pre-Release" : (checkStatement.ownerStatus === true ? "Downloaded" : "Released"))}</td>
                                    <td>{checkStatement.checkNumber}</td>
                                    <td>{checkStatement.checkAmountString}</td>
                                    <td>{checkStatement.checkDateString}</td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "100px", marginLeft: "20px" }} value={checkStatement.checkId}
                                            disabled={!checkStatement.cdexCodesMapped}
                                            onClick={(e) => downloadPdf('Pdf', e.currentTarget.value)}>
                                            Create PDF
                                            </Button>
                                    </td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "100px" }} value={checkStatement.checkId}
                                            disabled={!checkStatement.dataFileReleased}
                                            onClick={(e) => downloadPdf('Excel', e.currentTarget.value)}>
                                            Create Excel
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Container>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (<>
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerNumber">
                                <Form.Label>Owner Number</Form.Label>
                                <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={dataCriteria.ownerNumber} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerName">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control name="ownerName" size="sm" aria-label="Owner Name" value={dataCriteria.ownerName} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="checkNumber">
                                <Form.Label>Check Number</Form.Label>
                                <Form.Control name="checkNumber" size="sm" aria-label="Check Number" value={dataCriteria.checkNumber} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="formStatus">
                                <Form.Label>Owner Status</Form.Label>
                                <Form.Select name="checkStatusCriteriaId" size="sm" aria-label="Status" value={dataCriteria.checkStatusCriteriaId} disabled={dataStatus === 'loading'} onChange={handleChange} style={{width: '125px'}} >
                                    {checkStatusItems.map((status, i) => (
                                        <option key={status.id} value={status.id}>{status.status}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonClear">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='button'
                                    size='sm'
                                    disabled={pageStatus == 'loading' || dataStatus == 'loading' || notifyModalShow}
                                    onClick={pageStatus === 'ready' ? handleClearButton : null}
                                >
                                    Clear
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">                            
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='submit'
                                    size='sm'
                                    disabled={pageStatus == 'loading' || dataStatus == 'loading' || notifyModalShow}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>                                    
                                </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonNotification">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='button'
                                    size='sm'
                                    disabled={!dataFileReleased || pageStatus !== 'ready' || dataStatus !== 'ready' || notifyModalShow || !accountProfile.hasImpersonate}
                                    onClick={pageStatus === 'ready' ? handleNotifyOwnersButton : null}
                                >
                                    Registration Notification
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
        );
    }

    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <Container fluid>
                <Row>
                    <Col xs={1} style={{ marginLeft: '-13px' }} >
                        <span style={{ cursor: 'pointer', color: 'dodgerblue' }} onClick={() => navigate('/datafiles')}>&lt;&lt; Back</span>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    {/*<Col xs={1} style={{ marginLeft: '-13px' }} >*/}
                    {/*    */}{/*<span style={{ cursor: 'pointer' }} onClick={() => navigate('/datafiles')}>&lt;&lt; Back</span>*/}
                    {/*    <Button*/}
                    {/*        type='button'*/}
                    {/*        size='sm'*/}
                    {/*        style={{ marginTop: '10px' }}*/}
                    {/*        onClick={() => navigate('/datafiles')}*/}
                    {/*    >*/}
                    {/*        &lt;&lt; Back*/}
                    {/*        </Button>*/}
                    {/*</Col>*/}
                    <Col xs={4} style={{ marginLeft: '-13px' }}>
                        <h1>Check Statements</h1>
                    </Col>
                </Row>
                <Row>
                    
                </Row>
            </Container>
            {/*<div><br /></div>*/}
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <NotificationModal
                show={notifyModalShow}
                onHide={() => handleHideModal()}
                handleNextButton={handleNextButton}
                handleNotifyType={handleNotifyType}
                notifyType={notifyType}
                setIncludeStatements={setIncludeStatements}
                includeStatements={includeStatements}
                unregisteredOwners={unregisteredOwners}
                unregisteredOwnersCount={unregisteredOwnersCount}
                handleOwnerOption={handleOwnerOption}
                handleOwnerCheck={handleOwnerCheck}
                ownerOption={ownerOption}
                anyOwnerSelected={anyOwnerSelected}
                ownersPopulated={ownersPopulated}
                modalDataStatus={modalDataStatus}
                allLastSentDate={allLastSentDate}
            />

            <ConfirmationModal
                show={confirmModalShow}
                onHide={() => handleHideModal()}
                handleBackButton={() => handleBackButton()}
                handleSendButton={handleSendButton}
                handlePreviewButton={handlePreviewButton}
                displaySureModal={() => setSureModalShow(true)}
                notifyType={notifyType}
                setIncludeStatements={setIncludeStatements}
                includeStatements={includeStatements}
                unregisteredOwners={unregisteredOwners}
                dataStatus={dataStatus}
                isPreview={isPreview}
                userEmail={user.email}
            />

            <DisplayPdfModal
                show={displayPdfModalShow}
                onHide={() => handleHideDisplayPdfModal()}
                blobContent={blobContent}
                pdfPageNumber={pdfPageNumber}
                handlePdfPrevPage={handlePdfPrevPage}
                handlePdfNextPage={handlePdfNextPage}
                pdfNumPages={pdfNumPages}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
            />

            <SureModal
                show={sureModalShow}
                onHide={() => setSureModalShow(false)}
                handleSendButton={handleSendButton}
                allLastSentDate={allLastSentDate}
            />
      </div>

    );
}

export default Statements;
