import { Container, Row, Col } from 'react-bootstrap';


function DefaultFunc(props) {
    //render component
    return (
        <div>
            {(() => {
                if (false) {
                    return <Container>
                        <Row style={{ marginTop: '-12px', marginBottom: '-12px' }} >
                            <Col xs={8} style={{ marginLeft: '-12px' }} >
                                <h1>Hello World!</h1>
                            </Col>
                        </Row>
                    </Container>
                } else {
                    return <>
                        <h1>Hello World!</h1>
                        <div><br /></div>
                    </>
                }
            })()}

        </div>
    );
}

export default DefaultFunc;
