import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost } from "../services/AuthService.js";

const UploadEmails = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently, user } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 10
    });

    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('danger');
    const [alertMessage, setAlertMessage] = React.useState('Document downloaded successfully!');

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            await initializeForm()
                .then(response => {
                    setDataCriteria({
                        ...dataCriteria
                    });
                    setPageStatus('ready')
                    setDataStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //initialize form
    const initializeForm = async () => {
        //console.log('Initializing form...');

        return { success: true };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const changeHandler = (event) => {
        console.log('changing...');

        console.log('Number of selected files: ' + event.target.files.length);

        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            setIsFilePicked(true);
        }
        else {
            setSelectedFile(null);
            setIsFilePicked(false);
        }

        setDataStatus('ready')
    };

    const handleSubmission = async () => {
        setDataStatus('loading')

        const formData = new FormData();
        formData.append('uploadFile', selectedFile);

        await importFile()
            .then(response => {
                if (response.success) {
                    console.log('Success:', response.success);
                    setDataStatus('ready');

                    setAlertMessage("Document uploaded successfully!  We're processing your import now, and will send you an email when it's complete.");
                    setAlertShow(true);
                    setAlertVariant('success');
                }
                else {
                    console.log('Failed:', response.statusText + ' : ' + response);
                    setDataErrors('');
                    setDataStatus('error');

                    setAlertShow(true);
                    setAlertVariant('danger');
                    setAlertMessage('Upload failed.');
                }
            })
            .catch((ex) => {
                console.error('Error:', ex);
                setDataErrors(ex.message);
                setDataStatus('error');

                setAlertShow(true);
                setAlertVariant('danger');
                setAlertMessage('Upload failed.');
            })
            .finally(response => {
                setSelectedFile(null);
                setIsFilePicked(false);

                setTimeout(() => {
                    setAlertShow(false);
                }, 5000);
            });

        return { success: true };
    };

    //import file
    const importFile = async () => {
        //console.log('Importing file...');

        const formData = new FormData();
        formData.append('uploadFile', selectedFile);

        const apiUrl = 'a_import/emailupload?accountId=' + currentAccount.id;
        const body = formData;
        //console.log(apiUrl);
        //console.log('Json Body: ' + JSON.stringify(body));

        const response = await apiPost(apiUrl, body, false, getAccessTokenSilently);

        return { success: response.ok };
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was an import error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body>
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return (isFilePicked ?
                                    <div>
                                        <p>Filename: {selectedFile.name}</p>
                                        <p>Filetype: {selectedFile.type}</p>
                                        <p>Size in bytes: {selectedFile.size}</p>
                                        <p>
                                            lastModifiedDate:{' '}
                                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                                        </p>                                        
                                        <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                type='submit'
                                                size="sm"
                                                disabled={pageStatus !== 'ready'}
                                                onClick={pageStatus === 'ready' ? handleSubmission : null}
                                            >
                                                Upload/Import Emails
                                            </Button>
                                        </Form.Group>
                                        <p style={{ marginTop: '20px' }} >Note: An email will be sent to {user.email} when emails have been updated (usually within 24 hours).</p>
                                    </div>
                                    : <p>Select a file to show details.</p>
                                )
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto" >
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    disabled={pageStatus !== 'ready'}
                                    onChange={pageStatus === 'ready' ? changeHandler : null}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <h1>Upload Emails</h1>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
        </div>
    );
}

export default UploadEmails;
