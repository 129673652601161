import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost, apiGet, apiPutBlob } from "../services/AuthService.js";
import { BlockBlobClient } from "@azure/storage-blob";

const Import = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently, user } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 10,
        checkDate: '',
        description: ''
    });

    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('danger');
    const [alertMessage, setAlertMessage] = React.useState('Document downloaded successfully!');
    const [progressStatus, setProgressStatus] = useState(0);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await initializeForm()
                .then(response => {
                    setDataCriteria({
                        ...dataCriteria,
                        //TODO: temporarily commented checkDate out for development: uncomment for production
                        checkDate: response.currDate
                    });
                    setPageStatus('ready')
                    setDataStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //initialize form
    const initializeForm = async () => {
        //console.log('Initializing form...');

        //get check date
        let currentDate = await getCurrentDate();
        
        return { success: true, currDate: currentDate };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getCurrentDate = async () => {

        //TODO: apply timezone offset and set to first and last day of current month
        //https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        // This arrangement can be altered based on how we want the date's format to appear.
        //let currentDate = `${year}-${month}-${day}`;
        const currentDate = date.toJSON().split('T')[0];
        console.log('CurrentDate: ' + currentDate); // "17-6-2022"

        return currentDate;
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    const changeHandler = (event) => {
        console.log('changing...');

        console.log('Number of selected files: ' + event.target.files.length);

        if (event.target.files.length > 0) {
            if (!event.target.files[0].name.toLowerCase().endsWith('.xml')
                && !event.target.files[0].name.toLowerCase().endsWith('.txt')
                && !event.target.files[0].name.toLowerCase().endsWith('.dat')) {

                setSelectedFile(null);
                setIsFilePicked(false);
            }
            else {
                setSelectedFile(event.target.files[0]);
                setIsFilePicked(true);
            }
        }
        else {
            setSelectedFile(null);
            setIsFilePicked(false);
        }

        setDataStatus('ready')
    };

    const handleSubmission = async () => {
        setDataStatus('loading')

        const formData = new FormData();
        formData.append('uploadFile', selectedFile);

        const apiUrl = baseUrl + 'a_import?accountId=' + currentAccount.id + '&checkDate=' + dataCriteria.checkDate + '&description=' + dataCriteria.description;
        console.log(apiUrl);

        await importFile()
            .then(response => {
                if (response.success) {
                    console.log('Success:', response.success);
                    setDataStatus('ready');

                    setAlertMessage("Document uploaded successfully!  We've received your document for processing, and will send you emails regarding the status of the import.");
                    setAlertShow(true);
                    setAlertVariant('success');
                }
                else {
                    console.log('Failed:', response.statusText + ' : ' + response);
                    setDataErrors('');
                    setDataStatus('error');

                    setAlertShow(true);
                    setAlertVariant('danger');
                    setAlertMessage('Upload failed.');
                }
            })
            .catch((ex) => {
                console.error('Error:', ex);
                setDataErrors(ex.message);
                setDataStatus('error');

                setAlertShow(true);
                setAlertVariant('danger');
                setAlertMessage('Upload failed.');
            })
            .finally(response => {
                setSelectedFile(null);
                setIsFilePicked(false);

                setTimeout(() => {
                    setAlertShow(false);
                }, 5000);
            });

        return { success: true };
    };

    const handleBlobUpload = async () => {
        setDataStatus('loading')

        try {
            //reset progress indicator
            setProgressStatus(0);

            //create blob and get sas uri
            let blobSASUri = null;
            await getBlobSASUri()
                .then(response => {
                    if (response.success) {
                        blobSASUri = response.data;
                        //continue to uploadBlob below...
                    }
                    else {
                        throw 'Failed:', response.statusText + ' : ' + response;
                    }
                });

            //uploadBlob
            await uploadBlob(blobSASUri)
                .then(response => {
                    if (response.success) {
                        //continue to createImportProcess below...
                    }
                    else {
                        throw 'Failed:', response.statusText + ' : ' + response;
                    }
                });

            //createImportProcess
            const urlSegmentsArray = blobSASUri.replace("//", "/").split("/");
            const urlSubSegmentsArray = urlSegmentsArray[urlSegmentsArray.length - 1].split("?");
            const fileName = urlSubSegmentsArray[0];
            await createImportProcess(fileName)
                .then(response => {
                    if (response.success) {
                        console.log('Success:', response.success);
                        setDataStatus('ready');

                        setAlertMessage("Document uploaded successfully!  We've received your document for processing, and will send you emails regarding the status of the import.");
                        setAlertShow(true);
                        setAlertVariant('success');
                    }
                    else {
                        throw 'Failed:', response.statusText + ' : ' + response;
                    }
                });
        }
        catch (ex) {
            console.error('Error:', ex);
            setDataErrors(ex.message);
            setDataStatus('error');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Upload failed.');
        }
        finally {
            //reset file selection
            setSelectedFile(null);
            setIsFilePicked(false);

            //set the alert to close after 10 seconds
            setTimeout(() => {
                setAlertShow(false);
            }, 10000);
        }

        return { success: true };
    };
    
    //import file
    const importFile = async () => {
        //console.log('Importing file...');

        const formData = new FormData();
        formData.append('uploadFile', selectedFile);

        const apiUrl = 'a_import?accountId=' + currentAccount.id + '&checkDate=' + dataCriteria.checkDate + '&description=' + dataCriteria.description;
        const body = formData;
        //console.log(apiUrl);
        //console.log('Json Body: ' + JSON.stringify(body));

        const response = await apiPost(apiUrl, body, false, getAccessTokenSilently);

        return { success: response.ok };
    }
    //
    //get blob sas uri
    const getBlobSASUri = async () => {
        //console.log('Getting SAS Uri...');

        const apiUrl = 'a_import/createblob?accountId=' + currentAccount.id + '&checkDate=' + dataCriteria.checkDate + '&description=' + dataCriteria.description + '&uploadFileName=' + selectedFile.name;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const blobSASUri = await response.text();
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', blobSASUri: ' + blobSASUri);

        return { success: response.ok, data: blobSASUri };
    }

    //upload blob using sas
    const uploadBlob = async (sasTokenUrl) => {
        console.log('Uploading blob...');

        const blockBlobClient = new BlockBlobClient(sasTokenUrl);
        setProgressStatus(0);
        const response = await blockBlobClient.uploadBrowserData(selectedFile, {
            blockSize: 4 * 1024 * 1024,
            concurrency: 20,
            onProgress: ev => setProgressStatus(Math.floor((ev.loadedBytes / selectedFile.size) * 100))
        })
            .then((result) => {
                return { success: true };
            })
            .catch((ex) => {
                return { success: false, statusText: ex.message };
            });

        return response;
    };

    //create import process
    const createImportProcess = async (fileName) => {
        //console.log('Creating import process...');

        const apiUrl = 'a_import/createimportprocess?accountId=' + currentAccount.id + '&checkDate=' + dataCriteria.checkDate + '&description=' + dataCriteria.description + '&fileName=' + fileName;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok);

        return { success: response.ok };
    }

    //test read xml
    const testReadXml = async () => {
        await GetTestReadXml()
            .then(response => {
                if (response.success === true) {
                    //do something
                }
                else {
                    //do something
                }
            })
            .catch(ex => {
                setPageStatus('error');
                setPageErrors(ex.message);

                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            });
    }

    //populate user profile
    const GetTestReadXml = async () => {
        //console.log('Getting User Profile...');

        const apiUrl = 'a_import/testreadxml';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok);

        return { success: response.ok };
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <>
                <Row>
                    <p><b>{(progressStatus == 0 ? 'Preparing to upload...' : (progressStatus == 100 ? 'Completing upload...' : ''))}</b></p>
                    <ProgressBar animated now={progressStatus} label={`${progressStatus}%`} />
                </Row>
                <Row>
                <img src={spinnerIcon} alt="loading..." />
                </Row>
            </>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was an import error: {dataErrors}
            </Row>
        );
    }

    ////render loading
    //const showLoading_BAK = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{backgroundColor: 'lightgray'}}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body>             
                    {(() => {
                        switch (dataStatus) {
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return (isFilePicked ?
				                        <div>
					                        <p>Filename: {selectedFile.name}</p>
					                        <p>Filetype: {selectedFile.type}</p>
					                        <p>Size in bytes: {selectedFile.size}</p>
					                        <p>
						                        lastModifiedDate:{' '}
						                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                        </p>
                                        <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            {/*<Button*/}
                                            {/*    type='submit'*/}
                                            {/*    size="sm"*/}
                                            {/*    disabled={pageStatus !== 'ready'}*/}
                                            {/*    onClick={pageStatus === 'ready' ? handleSubmission : null}*/}
                                            {/*>*/}
                                            {/*    Upload/Import File*/}
                                            {/*</Button>      */}
                                            <Button
                                                type='submit'
                                                size="sm"
                                                disabled={pageStatus !== 'ready'}
                                                style={{marginLeft: '20px'}}
                                                onClick={pageStatus === 'ready' ? handleBlobUpload : null}
                                            >
                                                Upload Data File
                                            </Button>
                                        </Form.Group>
                                        <p style={{ marginTop: '20px' }} >Status emails will be sent to {user.email}.  Thank you!</p>
                                    </div>
                                    : <p>Select a file to show details.</p>
                                )
                            case 'error':
                                return showDataError()
                            default:
                                return <p>No Data Loaded.</p>
                        }
                    })()}
                </Card.Body>
            </Card>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container fluid>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="checkDate">
                                <Form.Label>Check Date</Form.Label>
                                <Form.Control type="date" placeholder="Check Date" name="checkDate" size="sm" aria-label="Check Date" value={dataCriteria.checkDate} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control name="description" size="sm" aria-label="Description" value={dataCriteria.description} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}>&nbsp;&nbsp;&nbsp;Supported file types: .XML, .TXT., .DAT</Form.Label>
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    disabled={pageStatus !== 'ready'}
                                    onChange={pageStatus === 'ready' ? changeHandler : null}
                                    accept=".xml, .txt, .dat"
                                />
                            </Form.Group>
                        </Col>

                        {/*<Col xs="auto">*/}
                        {/*    <Button*/}
                        {/*        size="sm"*/}
                        {/*        disabled={pageStatus !== 'ready'}*/}
                        {/*        style={{ marginLeft: '20px' }}*/}
                        {/*        onClick={pageStatus === 'ready' ? testReadXml : null}*/}
                        {/*    >*/}
                        {/*        TestReadXml*/}
                        {/*    </Button>*/}
                        {/*</Col>*/}
                    </Row>
                </Form>
            </Container>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            <h1>Import</h1>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
        </div>
    );
}

export default Import;
